import React from 'react'
import {get} from 'lodash'
import moment from 'moment'
import * as queryString from 'query-string'
import className from 'classnames'

import {axios} from '@lib/helpers'

import Tabs from '@components/molecules/Tabs'
import Modal from '@components/molecules/Modal'

import SourcesShowHeader from '@components/molecules/SourcesShowHeader'

const MembersIndex = (props) => {
  const {source, members, count} = props

  const [modalData, setModalData] = React.useState({
    isActive: false,
  })
  const [isLoading, setIsLoading] = React.useState(false)
  const [currentUser, setCurrentUser] = React.useState(null)
  const [autoApprove, setAutoApprove] = React.useState(
    get(source, 'auto_approve')
  )

  const renderTable = () => {
    const qs = queryString.parse(window.location.search)
    const filter = get(qs, 'filter', 'grant')

    const gridTemplateColumns =
      filter === 'grant' ? '1fr repeat(2, max-content)' : '1fr max-content'
    const tableRows =
      filter === 'grant'
        ? [
            {
              label: 'Line user',
            },
            {
              label: 'เวลา',
            },
            {
              label: '',
            },
          ]
        : [{label: 'Line user'}, {label: ''}]

    return (
      <div className='Table' style={{gridTemplateColumns}}>
        <div className='Table__row'>
          {tableRows.map((x, i) => (
            <div className='Table__col Table__col--header' key={i}>
              {get(x, 'label')}
            </div>
          ))}
        </div>
        {members.map((member) => {
          return renderByType(member.access, member)
        })}
      </div>
    )
  }

  const renderByType = (access, member) => {
    if (access) {
      return (
        <div className='Table__row' key={get(member, 'id')}>
          <div className='Table__col'>{get(member, 'user.name')}</div>
          <div className='Table__col'>
            {moment(get(member, 'updated_at')).format('YYYY-MM-DD HH:MM')}
          </div>
          <div className='Table__col'>
            {gon.current_user_id !== get(member, 'user.id') && (
              <button
                className='Button Button--red'
                onClick={() => {
                  setCurrentUser(member)
                  setModalData({isActive: true})
                }}
              >
                Block
              </button>
            )}
          </div>
        </div>
      )
    }

    return (
      <div className='Table__row' key={get(member, 'id')}>
        <div className='Table__col'>{get(member, 'user.name')}</div>
        <div className='Table__col'>
          <button
            className='Button Button--green'
            onClick={async () => {
              await handleManageAccess(member)
            }}
            disabled={isLoading}
          >
            Approve
          </button>
        </div>
      </div>
    )
  }

  const renderSidebar = () => {
    const sidebar = [
      {
        href: `?${queryString.stringify({filter: 'grant'})}`,
        color: '#00aefd',
        icon: (
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='18'
            height='18'
            viewBox='0 0 18 18'
          >
            <path
              fill='#CCC'
              d='M1 0C.448 0 0 .448 0 1v5.793c0 .445.539.668.854.353L2 6h8c.552 0 1-.448 1-1V1c0-.552-.448-1-1-1H1zm12 1v4c0 .771-.3 1.468-.78 2H16l1.146 1.146c.315.315.854.092.854-.353V2c0-.552-.448-1-1-1h-4zM4 9c-1.105 0-2 .895-2 2s.895 2 2 2 2-.895 2-2-.895-2-2-2zm10 0c-1.105 0-2 .895-2 2s.895 2 2 2 2-.895 2-2-.895-2-2-2zM4 15c-1.335 0-4 .67-4 2v.5c0 .276.224.5.5.5h7c.276 0 .5-.224.5-.5V17c0-1.33-2.665-2-4-2zm10 0c-1.335 0-4 .67-4 2v.5c0 .276.224.5.5.5h7c.276 0 .5-.224.5-.5V17c0-1.33-2.665-2-4-2z'
            />
          </svg>
        ),
        label: `ผู้ใช้งานที่อนุมัติ (${get(count, 'grant', 0)} คน)`,
        key: 'grant',
      },
      {
        href: `?${queryString.stringify({filter: 'deny'})}`,
        color: '#f97272',
        icon: (
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='20'
            height='20'
            viewBox='0 0 20 20'
          >
            <path
              fill='#CCC'
              d='M10 0C4.5 0 0 4.5 0 10s4.5 10 10 10 10-4.5 10-10S15.5 0 10 0zm5 11H5c-.552 0-1-.448-1-1s.448-1 1-1h10c.552 0 1 .448 1 1s-.448 1-1 1z'
            />
          </svg>
        ),
        label: `จำกัดสิทธิ์การเข้า (${get(count, 'deny', 0)} คน)`,
        key: 'deny',
      },
    ]

    return sidebar.map((menu, idx) => (
      <a href={menu.href} key={idx} className='MembersIndex__left__a'>
        <div
          className={className('MembersIndex__left__menu', {
            [`MembersIndex__left__menu--${menu.key}`]: sidebarActive(menu.key),
          })}
        >
          {menu.icon}
          <p>{menu.label}</p>
        </div>
      </a>
    ))
  }

  const sidebarActive = (type) => {
    const qs = queryString.parse(window.location.search)
    const filter = get(qs, 'filter', 'grant')

    return type === filter
  }

  const handleToggleAutoApprove = async ({target: {checked}}) => {
    setIsLoading(true)

    axios
      .put(
        `/sources/${get(source, 'source_type_name')}/${get(
          source,
          'source_id'
        )}`,
        {
          auto_approve: checked,
        }
      )
      .then((res) => setAutoApprove(checked))
  }

  const handleManageAccess = async ({id, access}) => {
    axios
      .put(
        `/sources/${get(source, 'source_type_name')}/${get(
          source,
          'source_id'
        )}/members/${id}`,
        {
          access: !access,
        }
      )
      .then((res) => window.location.reload())
  }

  return (
    <div className='MembersIndex'>
      <SourcesShowHeader source={source} />
      <Tabs source={source} denyCount={get(count, 'deny', 0)} />

      <div className='MembersIndex__wrapper'>
        <div className='MembersIndex__left'>
          <div className='MembersIndex__left__auto-approve'>
            <p className='MembersIndex__left__auto-approve__p'>Auto-Approve</p>
            <p className='MembersIndex__left__auto-approve__p MembersIndex__left__auto-approve__p--light'>
              ตั้งค่าอนุมัติผู้เข้าใช้งานแบบอัตโนมัติ
            </p>
            <div className='Switch'>
              <input
                type='checkbox'
                id='switch'
                onChange={handleToggleAutoApprove}
                checked={autoApprove}
              />
              <label htmlFor='switch' />
            </div>
          </div>
          {renderSidebar()}
        </div>

        <div className='MembersIndex__right'>
          {members.length ? (
            renderTable()
          ) : (
            <p className='MembersIndex__empty'>ไม่พบผู้ใช้งาน</p>
          )}
        </div>
      </div>

      <Modal modalData={modalData} modalDataSet={setModalData}>
        <form
          className='Modal__form'
          onSubmit={async (e) => {
            e.preventDefault()
            setIsLoading(true)
            await handleManageAccess(currentUser)
          }}
        >
          <h2 className='Modal__h2'>
            ต้องการ Block {get(currentUser, 'user.name')} ?
          </h2>
          <button
            className='Button Button--lg Button--red'
            type='submit'
            disabled={isLoading}
          >
            ยืนยัน
          </button>
          <button
            className='Button Button--lg Button--trans'
            type='button'
            onClick={() => setModalData({isActive: false})}
          >
            ยกเลิก
          </button>
        </form>
      </Modal>
    </div>
  )
}

export default MembersIndex
