import React, {useMemo, useState, useEffect} from 'react'
import {PaymentProvider} from '@datawowio/payment-ui'
import {isEmpty} from 'lodash'

import {ADDRESS_TYPE} from '../shared/constants'
import {useTransformPackgeData} from '../shared/hooks'
import {theme, project} from '../shared/theme'
import {createContext} from '../createContext'

const [OrderPageContextProvider, useOrderPageContext] = createContext({
  name: 'OrderPageContext',
})

export {useOrderPageContext}

export const withOrderPage = (WrappedComponent) => (props) => {
  const [addressType, setAddressType] = useState(
    getAddressType(props.addresses)
  )

  const [rawAddresses, setRawAddresses] = useState(props.addresses)
  const [selectedAddress, setSelectedAddress] = useState('')
  const [recurringType, setRecurringType] = useState(props.recurring)

  const addresses = useTransformAddresses(rawAddresses, addressType)
  const packageData = useTransformPackgeData(
    props.package,
    recurringType,
    addressType
  )

  useEffect(() => {
    setSelectedAddress(getDefaultSelected(addresses))
  }, [addressType, addresses])

  const contextValues = {
    hostUrl: props.host_url,
    partnerCode: props.partner_code,
    packageData,
    selectedAddress,
    setSelectedAddress,
    addressType,
    setAddressType,
    rawAddresses,
    setRawAddresses,
    addresses,
    recurringType,
    setRecurringType,
  }

  return (
    <OrderPageContextProvider value={contextValues}>
      <PaymentProvider project={project} theme={theme}>
        <WrappedComponent {...props}>{props.children}</WrappedComponent>
      </PaymentProvider>
    </OrderPageContextProvider>
  )
}

const useTransformAddresses = (data, addressType) => {
  const transformedAddresses = useMemo(() => {
    const transformedList = data.map((item) => {
      return {
        id: item.id,
        name: item.name,
        primary: item.primary,
        taxId: item.taxpayer_id,
        taxType: item.taxpayer_type,
        address: item.details,
      }
    })

    const filteredData = transformedList.filter(
      (item) => item.taxType === addressType
    )

    return filteredData
  }, [data, addressType])

  return transformedAddresses
}

const getDefaultSelected = (addresses) => {
  if (isEmpty(addresses)) {
    return ''
  }

  const primaryIndex = addresses.findIndex((data) => data.primary)
  const initialSelectedId = addresses[primaryIndex]?.id

  if (initialSelectedId) return initialSelectedId

  return addresses[0].id
}

const getAddressType = (addresses) => {
  if (isEmpty(addresses)) {
    return ADDRESS_TYPE.INDIVIDUAL
  }

  const primaryIndex = addresses.findIndex((data) => data.primary)
  const defaultAddressType = addresses[primaryIndex]?.taxpayer_type

  return defaultAddressType ?? ADDRESS_TYPE.INDIVIDUAL
}
