import React, {useState} from 'react'
import {get} from 'lodash'
import moment from 'moment'
import {FileIcon, defaultStyles} from 'react-file-icon'

import Tabs from '@components/molecules/Tabs'
import SourcesShowHeader from '@components/molecules/SourcesShowHeader'
import ModalEditRemark from '@components/molecules/ModalEditRemark'
import Pagination from '@components/molecules/Pagination'
import SearchForm from '@components/molecules/SearchForm'

import {start_and_end} from '@lib/helpers'

const FilesIndex = ({
  source,
  files = [],
  meta = {total: 0, current_page: 0, total_pages: 0},
}) => {
  const {total, current_page, total_pages} = meta
  const [modalData, modalDataSet] = useState({
    isActive: false,
  })

  return (
    <>
      <div className='FilesIndex'>
        <SourcesShowHeader source={source} />
        <Tabs source={source} />
        <SearchForm page='Files' />
        {get(files, 'length') > 0 ? (
          <div
            className='Table'
            style={{
              gridTemplateColumns:
                '60px 320px repeat(3, max-content) 1fr max-content',
            }}
          >
            <div className='Table__row'>
              {[
                {
                  label: '',
                },
                {
                  label: 'ชื่อไฟล์',
                },
                {
                  label: 'ประเภทไฟล์',
                },
                {
                  label: 'ผู้ส่ง',
                },
                {
                  label: 'เวลา',
                },
                {
                  label: 'โน๊ต',
                },
                {
                  label: '',
                },
              ].map((x, i) => (
                <div className='Table__col Table__col--header' key={i}>
                  {get(x, 'label')}
                </div>
              ))}
            </div>
            {files.map((x, i) => {
              const filename = get(x, 'filename').split('.')
              const extension = filename[filename.length - 1]
              const onClick = () => {
                modalDataSet({
                  isActive: true,
                  data: x,
                })
              }
              return (
                <div className='Table__row' key={i} data-x={x}>
                  <div className='Table__col'>
                    <div className='Table__col__preview'>
                      <FileIcon
                        extension={`${extension}`}
                        {...defaultStyles[`${extension}`]}
                      />
                    </div>
                  </div>
                  <div className='Table__col'>
                    {start_and_end(get(x, 'filename'))}
                  </div>
                  <div className='Table__col'>{get(x, 'storage_type')}</div>
                  <div className='Table__col'>{get(x, 'sender')}</div>
                  <div className='Table__col'>
                    {moment(get(x, 'updated_at')).format('YYYY-MM-DD HH:mm')}
                  </div>
                  <div className='Table__col__note'>
                    <a className='Table__col__note__edit' onClick={onClick} />
                    {get(x, 'remark') ? (
                      <div className='Table__col__note__text'>
                        <span
                          className='tooltiptext'
                          data-tooltip={get(x, 'remark')}
                        />
                        {/* <span className='tooltiptext'>{get(x, 'remark')}</span> */}
                        {get(x, 'remark')}
                      </div>
                    ) : (
                      <a className='Table__col__note__span' onClick={onClick}>
                        เพิ่มโน๊ต
                      </a>
                    )}
                  </div>
                  <div className='Table__col'>
                    <a
                      className='Table__col__a'
                      href={get(x, 'data.url')}
                      target='_blank'
                      rel='nofollow noopener noreferrer'
                    >
                      ดาวน์โหลดไฟล์
                    </a>
                  </div>
                </div>
              )
            })}

            <Pagination
              total_files={total}
              current_page={current_page}
              total_pages={total_pages}
            />
          </div>
        ) : (
          <div className='Placeholder'>
            <h6 className='Placeholder__h6'>ไม่พบไฟล์</h6>

            {false && (
              <React.Fragment>
                <p className='Placeholder__p'>
                  กรุณาทำการเชื่อมต่อห้องสนทนากับบอทก่อนใช้งาน
                </p>
              </React.Fragment>
            )}
          </div>
        )}
      </div>
      <ModalEditRemark modalData={modalData} modalDataSet={modalDataSet} />
    </>
  )
}

FilesIndex.propTypes = {}

export default FilesIndex
