import {useMemo} from 'react'

import {ADDRESS_TYPE} from './constants'

export const useTransformPackgeData = (data, recurringType, addressType) => {
  const recurringData = data.recurring[recurringType]
  const {vat, wht} = recurringData
  const price = recurringData.recurring_amount

  const getTotal = () => {
    if (addressType === ADDRESS_TYPE.INDIVIDUAL) {
      return price
    }

    return price - wht
  }

  const transformedPackageData = useMemo(() => {
    return {
      id: data.token,
      name: data.name,
      description: data.detail,
      price: price,
      net: price,
      vatPercent: recurringData.vat_percent,
      vat: vat,
      priceExcludeVat: Number(price - vat),
      total: getTotal(),
      ...(addressType === ADDRESS_TYPE.COMPANY && {
        companyVatPercent: recurringData.wht_percent,
        companyVat: wht,
      }),
    }
  }, [data, recurringType, addressType])

  return transformedPackageData
}
