import {get, entries, nth} from 'lodash'
import * as __axios from 'axios'

/*
 * Usage: t('user.title', {name: get(this.props, 'user.data.nickname')})
 */
export const t = (key, args = {}) => {
  try {
    let text = get(gon.locales, `${gon.currentLocale}.${key}`, key)
    const argsEntries = entries(args)

    if (argsEntries.length) {
      text = argsEntries.reduce((result, current) => {
        const pattern = new RegExp(`%{${nth(current, 0)}}`, 'g')

        return result.replace(pattern, nth(current, 1) || '')
      }, text)
    }

    return text
  } catch (e) {}
}

export const _axios = __axios.create({
  headers: {
    'X-CSRF-Token': document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute('content'),
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  responseType: 'json',
})

// We have our own lil' axios because it's easier
// to deal with `authenticity_token` in one-go.
export const axios = {
  get: (url, data) => {
    return _axios.get(url, data)
  },

  post: (url, data) => {
    return _axios.post(url, data)
  },

  patch: (url, data) => {
    return _axios.patch(url, data)
  },

  put: (url, data) => {
    return _axios.put(url, data)
  },

  delete: (url, data) => {
    return _axios.delete(url, {
      data,
    })
  },
}

export const downloadFile = (res, type) => {
  if (typeof window !== 'undefined') {
    const link = document.createElement('a')
    const url = window.URL.createObjectURL(new Blob([res.data]))
    const filename = getFileName(res) || `file.${type}`

    link.href = url
    link.download = filename
    link.click()
    link.remove()
  }
}

const getFileName = ({headers}) => {
  const disposition = headers['content-disposition']

  if (disposition && disposition.includes('attachment')) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
    const matches = filenameRegex.exec(disposition)
    if (matches !== null && matches[1]) {
      return matches[1].replace(/['"]/g, '')
    }
  }
}

export const getErrorMsg = (error) => {
  if (error.response) {
    if (typeof error.response.data === 'string') {
      return error.response.data
    }

    const errors = get(error, 'response.data.errors', [])

    if (errors.length > 0) {
      return errors[0]
    }

    if (errors.full_messages) {
      return get(errors, 'full_messages[0]', '')
    }

    const errorMsg = get(error, 'response.data.error', '')

    if (errorMsg !== '') {
      return errorMsg
    }

    return 'Something Wrong'
  } else if (error.request) {
    return 'Network Error'
  }

  return 'Something Wrong'
}

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return (
    parseFloat((bytes / Math.pow(k, i)).toFixed(dm)).toLocaleString() +
    ' ' +
    sizes[i]
  )
}

export const start_and_end = (str) => {
  if (str.length > 35) {
    return str.substr(0, 22) + '...' + str.substr(str.length - 10, str.length)
  }
  return str
}
