import React from 'react'
import PropTypes from 'prop-types'
import {get, replace, isString, first, isEmpty} from 'lodash'
import className from 'classnames'
import moment from 'moment'
import * as queryString from 'query-string'
import {Provider, useSelector, useDispatch} from 'react-redux'

import Tabs from '@components/molecules/Tabs'
import SourcesShowHeader from '@components/molecules/SourcesShowHeader'
import ImageLoader from '@components/molecules/ImageLoader'

import {loadMessages, store} from './store'

const SourcesShow = ({source}) => {
  const $wrapper = React.useRef()
  const $wrapperSearch = React.useRef()
  const $dl = React.useRef()
  const $loadMore = React.useRef()

  const dispatch = useDispatch()

  const isMessagesDisplayed = useSelector((state) => state.isMessagesDisplayed)
  const isSearching = useSelector((state) => state.isSearching)
  const messages = useSelector((state) => state.messages)
  const searchResults = useSelector((state) => state.searchResults)
  const searchMessages = useSelector((state) => state.searchMessages)

  const [query, querySet] = React.useState('')
  const [searchTarget, searchTargetSet] = React.useState()

  const observer = React.useRef(
    new IntersectionObserver((elements) => {
      const intersectionRatio = get(first(elements), 'intersectionRatio')

      if (intersectionRatio > 0) {
        $loadMore.current.click()
      }
    }) // Not to use `threshold` here because $loadMore will be clicked more than once.
  )

  if ($loadMore.current && isEmpty(observer.current.takeRecords())) {
    observer.current.observe($loadMore.current)
  }

  React.useEffect(() => {
    dispatch(loadMessages({source, messages})).then(() => {
      window.setTimeout(() => {
        $wrapper.current.scroll({top: get($dl, 'current.clientHeight')})

        window.setTimeout(() => {
          dispatch({
            type: 'MESSAGES_DISPLAY',
          })
        }, 100)
      }, 1000)
    })
  }, [])

  return (
    <div className='SourcesShow'>
      <SourcesShowHeader source={source} />

      <Tabs source={source} />

      <div
        className={className('Messages', {
          'Messages--searching': isSearching,
        })}
      >
        {get(source, 'import_in_progress', false) && (
          <p className='Messages__info'>กำลังนำเข้าข้อความ</p>
        )}

        <form
          className='Messages__search'
          onSubmit={(e) => {
            e.preventDefault()

            if (get(query, 'length') > 0) {
              dispatch({
                type: 'MESSAGES_SEARCH_RESULTS_LOAD',
                payload: {
                  client: 'default',
                  request: {
                    url: `/sources/${get(source, 'source_type_name')}/${get(
                      source,
                      'source_id'
                    )}/messages/search?${queryString.stringify({
                      query,
                    })}`,
                  },
                },
              })
            }
          }}
        >
          <input
            type='text'
            className='Input Input--search'
            value={query}
            onChange={(e) => {
              querySet(get(e, 'target.value'))
            }}
          />

          <button className='Button' disabled={get(searchResults, 'isLoading')}>
            ค้นหา
          </button>

          <a
            className='Button Button--trans'
            onClick={() => {
              querySet('')
              searchTargetSet()
              dispatch({
                type: 'MESSAGES_SEARCH_CLEAR',
              })
            }}
          >
            ล้าง
          </a>
        </form>

        <div
          className={className('Messages__wrapper', {
            'Messages__wrapper--active': !isSearching,
          })}
          ref={$wrapper}
        >
          {!isMessagesDisplayed && <p className='Placeholder'>Loading …</p>}

          <dl
            className={className('Messages__dl', {
              'Messages__dl--active': isMessagesDisplayed,
            })}
            ref={$dl}
          >
            {get(messages, 'data.meta.prev') && (
              <a
                className='LoadMore'
                disabled={get(messages, 'isLoading')}
                ref={$loadMore}
                onClick={() => {
                  const top = get($dl, 'current.clientHeight')

                  if (!get(messages, 'isLoading')) {
                    dispatch(loadMessages({source, messages})).then(() => {
                      $wrapper.current.scroll({
                        top: get($dl, 'current.clientHeight') - top,
                      })
                    })
                  }
                }}
              >
                {get(messages, 'isLoading') ? 'Loading …' : 'Load more'}
              </a>
            )}

            <Messages messages={messages} />
          </dl>
        </div>

        {
          // We need to separate `Messages__wrapper` for normal messages
          // and search messages here in order to maintain the scroll position
          // of the normal messages panel once switch back from search mode.
          // Opacity does the trick.
        }
        <div
          className={className('Messages__wrapper', {
            'Messages__wrapper--active': isSearching,
          })}
          ref={$wrapperSearch}
        >
          {get(searchMessages, 'isLoading') && (
            <p className='Placeholder'>Loading …</p>
          )}

          <dl
            className={className('Messages__dl', {
              'Messages__dl--active': isMessagesDisplayed,
            })}
          >
            <Messages messages={searchMessages} />
          </dl>
        </div>

        <div className='Messages__search-results'>
          {get(searchResults, 'isLoading') ? (
            <p className='Placeholder'>Loading …</p>
          ) : (
            <React.Fragment>
              <div className='Messages__search-results__header'>
                <h4 className='Messages__search-results__header__h4'>
                  ค้นพบ ‘{get(searchResults, 'data.meta.query')}’ (
                  {get(searchResults, 'data.messages.length', 0)}{' '}
                  ข้อความที่ถูกพบ)
                </h4>

                {false && (
                  <React.Fragment>
                    <a
                      className='Messages__search-results__header__a Messages__search-results__header__a--prev'
                      disabled
                    />

                    <a className='Messages__search-results__header__a' />
                  </React.Fragment>
                )}
              </div>

              <div className='Messages__search-results__wrapper'>
                {get(searchResults, 'data.messages.length') > 0 && (
                  <dl className='Messages__search-results__dl'>
                    {(get(searchResults, 'data.messages') || []).map((x, i) => (
                      <a
                        className={className(
                          'Messages__search-results__dl__item',
                          {
                            'Messages__search-results__dl__item--active':
                              get(x, 'id') == get(searchTarget, 'id'),
                          }
                        )}
                        key={i}
                        onClick={() => {
                          searchTargetSet(x)

                          dispatch({
                            type: 'MESSAGES_SEARCH_LOAD',
                            payload: {
                              client: 'default',
                              request: {
                                url: `/sources/${get(
                                  source,
                                  'source_type_name'
                                )}/${get(
                                  source,
                                  'source_id'
                                )}/messages?${queryString.stringify({
                                  id: get(x, 'id'),
                                })}`,
                              },
                            },
                          }).then(() => {
                            const $target = $wrapperSearch.current.querySelector(
                              '.Messages__dl__item--highlighted'
                            )

                            if ($target.previousElementSibling) {
                              $target.previousElementSibling.scrollIntoView()
                            }
                          })
                        }}
                      >
                        <img
                          className='Messages__search-results__dl__item__img'
                          src={get(x, 'user.image_url')}
                        />

                        <div className='Messages__search-results__dl__item__content'>
                          <dt className='Messages__search-results__dl__item__dt'>
                            {get(x, 'user.name')}
                          </dt>

                          <time className='Messages__dl__item__time'>
                            {moment(get(x, 'created_at')).format(
                              'YYYY-MM-DD HH:mm'
                            )}
                          </time>

                          {(() => {
                            let text =
                              get(x, 'data.text') || get(x, 'message_type')

                            const query = get(searchResults, 'data.meta.query')

                            text = formatText({text, query})

                            return (
                              <dd
                                className='Messages__search-results__dl__item__dd'
                                dangerouslySetInnerHTML={{__html: text}}
                              />
                            )
                          })()}
                        </div>
                      </a>
                    ))}
                  </dl>
                )}
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  )
}

SourcesShow.propTypes = {}

const Messages = ({messages}) => {
  const searchResults = useSelector((state) => state.searchResults)
  const query = get(searchResults, 'data.meta.query')

  return (
    <React.Fragment>
      {(get(messages, 'data.messages') || []).map((x, i) => (
        <div
          className={className('Messages__dl__item', {
            'Messages__dl__item--self':
              get(x, 'user.id') == gon.current_user_id,
            'Messages__dl__item--highlighted':
              get(messages, 'data.meta.id') == get(x, 'id'),
          })}
          key={get(x, 'id')}
        >
          <img
            className='Messages__dl__item__img'
            src={get(x, 'user.image_url')}
          />

          <div className='Messages__dl__item__content'>
            <dt className='Messages__dl__item__dt'>{get(x, 'user.name')}</dt>

            <dd className={className('Messages__dl__item__dd', {})} data-x={x}>
              {(() => {
                switch (get(x, 'message_type')) {
                  case 'text':
                    const text = get(x, 'data.text')

                    let textArray = []
                    let textArrayIndex = 0

                    if (get(x, 'data.emojis.length') > 0) {
                      get(x, 'data.emojis').forEach((y) => {
                        textArray = [
                          ...textArray,
                          text.substring(textArrayIndex, get(y, 'index')),
                          <ImageLoader
                            klass='Messages__dl__item__dd__sticker-inline'
                            src={`https://stickershop.line-scdn.net/sticonshop/v1/sticon/${get(
                              y,
                              'productId'
                            )}/iPhone/${get(y, 'emojiId')}.png`}
                          />,
                        ]

                        textArrayIndex = get(y, 'index') + get(y, 'length')
                      })

                      textArray = [...textArray, text.substring(textArrayIndex)]
                    } else {
                      textArray = [text]
                    }

                    return (
                      <React.Fragment>
                        {textArray.map((x, i) => (
                          <React.Fragment key={i}>
                            {isString(x) ? (
                              <span
                                className='Messages__dl__item__dd__span'
                                dangerouslySetInnerHTML={{
                                  __html: formatText({text: x, query}),
                                }}
                              />
                            ) : (
                              x
                            )}
                          </React.Fragment>
                        ))}
                      </React.Fragment>
                    )

                  case 'sticker':
                    return (
                      <ImageLoader
                        klass='Messages__dl__item__dd__sticker'
                        src={`https://stickershop.line-scdn.net/stickershop/v1/sticker/${get(
                          x,
                          'data.stickerId'
                        )}/android/sticker.png;compress=true`}
                      />
                    )

                  case 'image':
                    return (
                      <ImageLoader
                        klass='Messages__dl__item__dd__image'
                        src={get(x, 'storage.data.url')}
                      />
                    )

                  case 'video':
                    return (
                      <ImageLoader
                        klass='Messages__dl__item__dd__video'
                        src={get(x, 'storage.data.url')}
                        type='video'
                      />
                    )

                  case 'audio':
                    return (
                      <ImageLoader
                        klass='Messages__dl__item__dd__audio'
                        src={get(x, 'storage.data.url')}
                        type='audio'
                      />
                    )

                  case 'file':
                    return (
                      <a
                        className='Messages__dl__item__dd__file'
                        href={get(x, 'storage.data.url')}
                        target='_blank'
                        rel='nofollow noopener noreferrer'
                      >
                        {get(x, 'storage.data.filename')}
                      </a>
                    )

                  case 'location':
                    return (
                      <div className='Messages__dl__item__dd__location'>
                        <p className='Messages__dl__item__dd__location__p'>
                          {get(x, 'data.address')}
                        </p>

                        <ImageLoader
                          klass='Messages__dl__item__dd__location__iframe'
                          src={`https://maps.google.com/maps?q=${get(
                            x,
                            'data.latitude'
                          )},${get(x, 'data.longitude')}&z=16&output=embed`}
                          type='location'
                        />
                      </div>
                    )

                  default:
                    return get(x, 'message_type')
                }
              })()}
            </dd>

            <time className='Messages__dl__item__time'>
              {get(x, 'imported', false) && 'ข้อความนำเข้า '}
              {moment(get(x, 'created_at')).format('YYYY-MM-DD HH:mm')}
            </time>
          </div>
        </div>
      ))}
    </React.Fragment>
  )
}

const formatText = ({text, query}) => {
  if (!query) {
    return text
  }

  return replace(text, new RegExp(query, 'i'), `<strong>${query}</strong>`)
}

const SourcesShowWrapper = (props) => {
  return (
    <Provider store={store}>
      <SourcesShow {...props} />
    </Provider>
  )
}

export default SourcesShowWrapper
