import React from 'react'
import PropTypes from 'prop-types'
import {get} from 'lodash'
import className from 'classnames'
// import {Link} from 'react-router-dom'

const Tabs = ({source, denyCount}) => {
  const iAmOwner = get(source, 'user.id') === gon.current_user_id
  const iAmEnterprise = get(source, 'enterprise', false)

  const filterTab = (tab, idx) => {
    const {filter = {}} = tab
    return Object.keys(filter).every((key) => get(source, key) === filter[key])
  }

  return (
    <div className='Tabs'>
      {[
        {
          label: 'ข้อความ',
          href: `/sources/${get(source, 'source_type_name')}/${get(
            source,
            'source_id'
          )}`,
        },
        {
          label: 'ไฟล์',
          href: `/sources/${get(source, 'source_type_name')}/${get(
            source,
            'source_id'
          )}/files`,
        },
        {
          label: 'สื่อมัลติมีเดีย',
          href: `/sources/${get(source, 'source_type_name')}/${get(
            source,
            'source_id'
          )}/media`,
        },
        {
          label: 'ผู้ใช้งาน',
          href: `/sources/${get(source, 'source_type_name')}/${get(
            source,
            'source_id'
          )}/members`,
          filter: {'user.id': gon.current_user_id},
        },
        {
          label: 'สำรอง/นำเข้า ประวัติการแชท',
          href: `/sources/${get(source, 'source_type_name')}/${get(
            source,
            'source_id'
          )}/import`,
          filter: {'user.id': gon.current_user_id},
        },
        {
          label: 'การเข้ารหัสบทสนทนา',
          href: `/sources/${get(source, 'source_type_name')}/${get(
            source,
            'source_id'
          )}/encryption`,
          ownerOnly: true,
          isEnterprise: true,
          filter: {enterprise: true, 'user.id': gon.current_user_id},
        },
      ]
        .filter(filterTab)
        .map((x, i) => {
          return (
            <a
              key={i}
              href={get(x, 'href')}
              className={className('Tabs__a', {
                'Tabs__a--active':
                  get(x, 'href') == get(window, 'location.pathname'),
                'Tabs__a--noti': denyCount && get(x, 'label') === 'ผู้ใช้งาน',
              })}
            >
              {get(x, 'label')}
            </a>
          )
        })}
      <span
        className={className('Badge', {
          'Badge--gray': !get(source, 'connected'),
        })}
      >
        สถานะ: {get(source, 'connected') ? 'เชื่อมต่อแล้ว' : 'ยังไม่เชื่อมต่อ'}
      </span>
    </div>
  )
}

Tabs.propTypes = {}

export default Tabs
