import React, {useState, useRef} from 'react'
import className from 'classnames'
import PropTypes from 'prop-types'

const Accordion = (props) => {
  const {head, body} = props

  const [open, setOpen] = useState(false)
  const childRef = useRef()

  const calcChildrenHeight = () => {
    const child = childRef.current

    return `${child.height || child.offsetHeight || 2000}px`
  }

  return (
    <div className='Accordion'>
      <div
        className={className('Accordion__head', {
          'Accordion__head--open': open,
        })}
        onClick={() => setOpen((state) => !state)}
      >
        {head}
        <div className='Accordion__head__arrow' />
      </div>
      <div
        className={className('Accordion__body')}
        style={{...(open && {maxHeight: calcChildrenHeight()})}}
      >
        <div className='Accordion__body__wrapper' ref={childRef}>
          {body}
        </div>
      </div>
    </div>
  )
}

Accordion.propTypes = {
  head: PropTypes.string,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
}

export default Accordion
