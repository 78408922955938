import React from 'react'
// import PropTypes from 'prop-types'
import {get} from 'lodash'
import className from 'classnames'
import moment from 'moment'

import TabsPackages from '@components/molecules/TabsPackages'
// import Plans from '@components/molecules/Plans'

const TransactionsIndex = ({transactions = []}) => {
  return (
    <div className='TransactionsIndex'>
      <div className='Header'>
        <h2 className='Header__h2'>
          <span className='Header__breadcrumb__current'>ใบเสร็จ</span>
        </h2>
      </div>

      <TabsPackages />

      <div
        className='Table'
        style={{
          gridTemplateColumns:
            'max-content max-content max-content 1fr max-content',
        }}
      >
        <div className='Table__row'>
          {[
            {
              label: 'วันที่ทำรายการ',
            },
            {
              label: 'ช่องทางการชำระ',
            },
            {
              label: 'จำนวนเงิน',
            },
            {
              label: 'สถานะการชำระ',
            },
            {
              label: 'ใบเสร็จรับเงิน',
            },
          ].map((x, i) => (
            <div className='Table__col Table__col--header' key={i}>
              {get(x, 'label')}
            </div>
          ))}
        </div>
        {transactions.map((x, i) => (
          <div className='Table__row' key={i}>
            <div className='Table__col'>
              {moment(get(x, 'updated_at')).format('YYYY-MM-DD HH:mm')}
            </div>

            <div className='Table__col'>{get(x, 'payment_type')}</div>

            <div className='Table__col'>{get(x, 'amount')} บาท</div>

            <div className='Table__col'>
              <span
                className={className('Badge', {
                  'Badge--red': !(
                    get(x, 'paid') || get(x, 'status') === 'ชำระแล้ว'
                  ),
                  // 'Badge--gray':
                  //   get(x, 'status') !== 'รอตรวจสอบ', //pending
                })}
              >
                {get(x, 'paid') || get(x, 'status') === 'ชำระแล้ว'
                  ? 'ชำระแล้ว'
                  : 'ชำระไม่สำเร็จ'}
              </span>
            </div>
            <div className='Table__col'>
              {get(x, 'receipt_url') && (
                <a
                  href={get(x, 'receipt_url')}
                  className='Table__col__a'
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  ดูใบเสร็จ
                </a>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

TransactionsIndex.propTypes = {}

export default TransactionsIndex
