export const theme = {
  colors: {
    brand: {
      50: '#99DFFF',
      100: '#5CCBFF',
      200: '#47C5FF',
      300: '#33BEFF',
      400: '#1FB8FF',
      500: '#00AEFD',
      600: '#00A7F5',
      700: '#0099E0',
      800: '#008BCC',
      900: '#007DB8',
    },
  },
}

export const project = {
  title: 'JotT',
  email: 'sales@mplus.co.th',
  productType: 'Chat Storage',
}
