import React from 'react'
import {
  TableOrder,
  Box,
  Text,
  Grid,
  Summary,
  SelectAddress,
  SelectAddressItem,
  AddressForm,
  useToast,
} from '@datawowio/payment-ui'
import {capitalize} from 'lodash'

import {useOrderPageContext, withOrderPage} from './store'
import {useOrderPageServices} from './services'

function AfterSelectPackage() {
  const {
    packageData,
    addresses,
    addressType,
    setAddressType,
    selectedAddress,
    setSelectedAddress,
    setRecurringType,
    recurringType,
    hostUrl,
  } = useOrderPageContext()

  const OrderPageServices = useOrderPageServices()

  const [errors, setErrors] = React.useState({})
  const toast = useToast()

  React.useEffect(() => {
    if (errors.selectedAddress) {
      toast({
        title: errors.selectedAddress,
        status: 'error',
        position: 'top',
        isClosable: true,
      })
    }
  }, [errors])

  return (
    <Box backgroundColor='#fff' p='6' borderRadius='8px'>
      <Box mb='12'>
        <Text as='h2' fontSize='2xl' fontWeight='700'>
          สรุปรายการสั่งซื้อ
        </Text>
      </Box>
      <Grid gap='6' gridTemplateColumns='1fr 440px' alignItems='start'>
        <Grid gap='6'>
          <Box>
            <TableOrder
              id={packageData.id}
              name={packageData.name}
              recurring={recurringType}
              price={packageData.price}
              net={packageData.net}
              onChangePackage={(data) => {
                setRecurringType(data.recurring)
              }}
            />
          </Box>
          <Box>
            <SelectAddress
              addressType={addressType}
              onChangeType={(type) => setAddressType(type)}
              renderAddressForm={({onClose}) => (
                <AddressForm
                  addressType={addressType}
                  onSubmit={(values) => {
                    OrderPageServices.createAddress(values, {
                      onSuccess: () => onClose(),
                    })
                  }}
                />
              )}
            >
              {addresses.map((item) => {
                const {id: addressId, name, address, primary, taxId} = item

                return (
                  <SelectAddressItem
                    key={addressId}
                    id={addressId}
                    name={name}
                    address={address}
                    taxId={taxId}
                    primary={primary}
                    isSelected={selectedAddress === addressId}
                    onDelete={() => {
                      OrderPageServices.deleteAddress(addressId)
                    }}
                    onEdited={(values) => {
                      OrderPageServices.updateAddress({
                        id: addressId,
                        ...values,
                      })
                    }}
                    onClickSetPrimary={(values) => {
                      OrderPageServices.updateAddress({
                        id: addressId,
                        ...values,
                        primary: true,
                      })
                    }}
                    onClick={() => {
                      setSelectedAddress(addressId)
                    }}
                  />
                )
              })}
            </SelectAddress>
          </Box>
        </Grid>
        <Box>
          <Summary
            net={packageData.priceExcludeVat}
            total={packageData.total}
            vat={packageData.vat}
            vatPercent={packageData.vatPercent}
            preVatTotal={packageData.priceExcludeVat}
            name={capitalize(packageData.name)}
            onErrors={(err) => {
              setErrors(err)
            }}
            extraValues={{
              selectedAddress,
            }}
            validate={(values) => {
              const errors = {}

              if (!values.selectedAddress) {
                errors.selectedAddress =
                  'จำเป็นต้องเพิ่มที่อยู่สำหรับออกใบกำกับภาษี'
              }

              return errors
            }}
            displayAcceptTerms={false}
            onSubmit={() => {
              OrderPageServices.saveInformation()
            }}
            linkTermOfUse={`${hostUrl}/terms`}
            linkPrivacy={`${hostUrl}/privacy`}
            withHoldingTaxPercent={packageData.companyVatPercent}
            withHoldingTax={packageData.companyVat}
          />
        </Box>
      </Grid>
    </Box>
  )
}

export default withOrderPage(AfterSelectPackage)
