import React from 'react'
import PropTypes from 'prop-types'
import {get} from 'lodash'
import className from 'classnames'
import smoothscroll from 'smoothscroll-polyfill'
import {motion} from 'framer-motion'

import Hamburger from '@components/molecules/Hamburger'

const NavLanding = ({
  root_path,
  new_user_session_path,
  internal_links = [],
}) => {
  const [isActive, isActiveSet] = React.useState(false)

  React.useEffect(() => {
    smoothscroll.polyfill()
  }, [])

  return (
    <div className='NavLanding'>
      <a
        className={className('NavLanding__overlay', {
          'NavLanding__overlay--active': isActive,
        })}
        onClick={() => {
          isActiveSet(false)
        }}
      />

      <div className='container'>
        <h1 className='NavLanding__h1'>
          <a
            className='NavLanding__h1__a'
            href={root_path}
            onClick={(e) => {
              if (typeof document) {
                if (window.location.pathname === '/') {
                  e.preventDefault()

                  isActiveSet(false)
                  window.history.pushState(null, null, `/`)
                  window.scroll({top: 0, behavior: 'smooth'})
                }
              }
            }}
          >
            JotT
          </a>
        </h1>

        <Hamburger
          klass='NavLanding__handle'
          isActive={isActive}
          isActiveSet={isActiveSet}
        />

        <div
          className={className('NavLanding__links', {
            'NavLanding__links--active': isActive,
          })}
          style={{
            '--NavLanding__links-count': get(internal_links, 'length', 0),
          }}
        >
          {[
            ...internal_links,
            {
              label: 'ให้น้องช่วยจด เริ่มเลย!',
              href: new_user_session_path,
              klass: 'orange',
            },
          ].map((x, i) => (
            <a
              key={i}
              className={className('NavLanding__links__a', {
                [`NavLanding__links__a--${get(x, 'klass')}`]: get(x, 'klass'),
              })}
              href={get(x, 'href') || `${root_path}${get(x, 'anchor')}`}
              onClick={(e) => {
                if (typeof document) {
                  if (get(x, 'anchor')) {
                    if (window.location.pathname === '/') {
                      e.preventDefault()

                      isActiveSet(false)
                      window.history.pushState(
                        null,
                        null,
                        `${get(x, 'anchor')}`
                      )
                      document
                        .querySelector(get(x, 'anchor'))
                        .scrollIntoView({behavior: 'smooth'})
                    }
                  }
                }
              }}
            >
              {get(x, 'label')}
            </a>
          ))}
        </div>
      </div>
    </div>
  )
}

NavLanding.propTypes = {}

export default NavLanding
