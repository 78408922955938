import React from 'react'

import {Box, Text} from '@datawowio/payment-ui'

const ExtertedField = ({children, description, title}) => {
  return (
    <Box
      sx={{
        border: '1px solid',
        borderColor: 'gray.200',
        borderRadius: '8px',
        p: '6',
      }}
    >
      <Text
        sx={{
          fontWeight: 'bold',
          fontSize: '20px',
          mb: 1,
        }}
      >
        {title}
      </Text>
      <Text
        sx={{
          fontSize: '14px',
          mb: 4,
        }}
      >
        {description}
      </Text>
      {children}
    </Box>
  )
}

export default ExtertedField
