import React from 'react'
import {get} from 'lodash'
import className from 'classnames'

import {axios} from '@lib/helpers'
const Nav = ({current_user, links = [], sign_in_path}) => {
  return (
    <div className='Nav'>
      <div className='container'>
        <h1 className='Nav__h1'>
          <a className='Nav__h1__a' href={get(links, '0.href')}>
            JotT
          </a>
        </h1>

        <div className='Nav__links'>
          {links.map((x, i) => (
            <a
              className={className('Nav__links__a', {
                'Nav__links__a--active': get(x, 'is_active'),
              })}
              key={i}
              href={get(x, 'href')}
              disabled={!get(x, 'href')}
            >
              {get(x, 'label')}
            </a>
          ))}
        </div>

        {current_user ? (
          <div className='Nav__profile'>
            <div className='Nav__profile__left'>
              <span className='Nav__profile__span'>
                {get(current_user, 'name')}
              </span>

              {false && (
                <small className='Nav__profile__small'>
                  Assignment of husbands must make up millions.
                </small>
              )}
            </div>

            {get(current_user, 'image_url') ? (
              <img
                className='Nav__profile__img'
                src={get(current_user, 'image_url')}
              />
            ) : (
              <div className='Nav__profile__img'></div>
            )}

            <div className='Nav__profile__panel'>
              <div className='Nav__profile__panel__wrapper'>
                <div className='Nav__profile__panel__links'>
                  {[
                    {
                      label: 'ข้อมูลส่วนตัว',
                      href: '/profile',
                    },
                    {
                      label: 'ออกจากระบบ',
                      klass: 'red',
                      onClick: () => {
                        axios.delete('/sign_out').finally(() => {
                          window.location.reload()
                        })
                      },
                    },
                  ].map((x, i) => (
                    <a
                      className={className('Nav__profile__panel__links__a', {
                        [`Nav__profile__panel__links__a--${get(
                          x,
                          'klass'
                        )}`]: get(x, 'klass'),
                      })}
                      key={i}
                      href={get(x, 'href')}
                      onClick={() => {
                        if (get(x, 'onClick')) {
                          x.onClick()
                        }
                      }}
                    >
                      {get(x, 'label')}
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <a
            className='Button Button--line'
            href={`${window.location.origin}${sign_in_path}`}
          >
            เข้าสู่ระบบผ่าน LINE
          </a>
        )}
      </div>
    </div>
  )
}

Nav.propTypes = {}

export default Nav
