import React from 'react'
import {get} from 'lodash'
import className from 'classnames'
import {axios} from '@lib/helpers'

import ModalEditRoomName from '@components/molecules/ModalEditRoomName'
import Guide from '@components/molecules/Guide'

const SourcesIndex = ({token, data = []}) => {
  const [pageData, setPageData] = React.useState(data)

  const [modalData, modalDataSet] = React.useState({
    isActive: false,
  })

  const onChange = (e) => {
    const {checked} = e.target

    const [index, type] = e.target.id.split('|')
    axios
      .patch(`/source_settings/notification`, {
        ids:
          index === 'all'
            ? pageData.map((data) => data.id)
            : [pageData[index].id],
        type: type?.includes('line') ? 'line' : 'email',
        enable: JSON.stringify(checked),
      })
      .then(() => {
        setPageData((prev) => setPageDataFnc(prev, index, type, checked))
      })
      .catch(() => {
        setPageData((prev) => setPageDataFnc(prev, index, type, !checked))
      })
  }

  const setPageDataFnc = (prev, index, type, checked) => {
    return prev.map((el, i) =>
      Number(i) === Number(index) || index === 'all'
        ? {
            ...el,
            [type]: checked,
          }
        : el
    )
  }

  return (
    <React.Fragment>
      <div className='Header'>
        <h2 className='Header__h2'>
          <span className='Header__breadcrumb__current'>ห้องสนทนา</span>
        </h2>

        <p className='Header__key'>Verify Key: {token}</p>
      </div>

      <Guide token={token} />

      {get(pageData, 'length') > 0 ? (
        <div
          className='Table'
          style={{
            gridTemplateColumns: 'repeat(6, minmax(max-content, 1fr))',
          }}
        >
          <div className='Table__row'>
            {[
              {
                label: 'ห้องสนทนา',
              },
              {
                label: 'เจ้าของห้อง',
              },
              {
                label: 'สถานะการเชื่อมต่อ',
              },
              {
                label: 'แจ้งเตือนผ่านอีเมล',
                backgroundColor: '#F4F9FF',
                col: 'enable_email_noti',
              },
              {
                label: 'แจ้งเตือนผ่าน LINE',
                backgroundColor: '#EAF3FF',
                col: 'enable_line_noti',
              },
              {
                label: '',
              },
            ].map((x, i) => (
              <div
                className='Table__col Table__col--header'
                key={i}
                style={{backgroundColor: get(x, 'backgroundColor')}}
              >
                {get(x, 'label')}
                {get(x, 'col') && (
                  <div className='Switch Table__col--right-item'>
                    <input
                      type='checkbox'
                      id={`all|${get(x, 'col')}`}
                      checked={
                        !pageData
                          .map((d) => get(d, get(x, 'col')) ?? false)
                          .some((d) => !d)
                      }
                      onChange={onChange}
                    />
                    <label htmlFor={`all|${get(x, 'col')}`} />
                  </div>
                )}
              </div>
            ))}
          </div>
          {pageData.map((x, i) => (
            <div className='Table__row' key={i}>
              <div className='Table__col'>
                {(() => {
                  const onClick = () => {
                    modalDataSet({
                      isActive: true,
                      data: x,
                    })
                  }

                  return (
                    <div className='Table__col__chatroom-name'>
                      {get(x, 'user.id') === gon.current_user_id && (
                        <a
                          className='Table__col__chatroom-name__edit'
                          onClick={onClick}
                        />
                      )}

                      {get(x, 'name') ? (
                        <a
                          className='Table__col__chatroom-name__a'
                          href={`/sources/${get(x, 'source_type_name')}/${get(
                            x,
                            'source_id'
                          )}`}
                        >
                          {get(x, 'name')}
                        </a>
                      ) : get(x, 'user.id') === gon.current_user_id ? (
                        <a
                          className='Table__col__chatroom-name__span'
                          onClick={onClick}
                        >
                          กรุณาตั้งชื่อห้องสนทนา
                        </a>
                      ) : (
                        <a
                          className='Table__col__chatroom-name__span'
                          href={`/sources/${get(x, 'source_type_name')}/${get(
                            x,
                            'source_id'
                          )}`}
                        >
                          ไม่มีชื่อห้องสนทนา
                        </a>
                      )}
                    </div>
                  )
                })()}
              </div>

              <div className='Table__col'>{get(x, 'user.name')}</div>

              <div className='Table__col'>
                <span
                  className={className('Badge', {
                    'Badge--gray': !get(x, 'connected'),
                  })}
                >
                  {get(x, 'connected') ? 'เชื่อมต่อแล้ว' : 'ยังไม่เชื่อมต่อ'}
                </span>
              </div>
              <NotiField
                data={x}
                type='enable_email_noti'
                index={i}
                onChange={onChange}
                style={{backgroundColor: '#F4F9FF'}}
              />
              <NotiField
                data={x}
                type='enable_line_noti'
                index={i}
                onChange={onChange}
                style={{backgroundColor: '#EAF3FF'}}
              />
              <div className='Table__col' style={{justifyContent: 'center'}}>
                <a
                  className='Table__col__enter-chat-room'
                  href={`/sources/${get(x, 'source_type_name')}/${get(
                    x,
                    'source_id'
                  )}`}
                >
                  เข้าสู่ห้องสนทนา
                </a>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className='Placeholder'>
          <h6 className='Placeholder__h6'>ไม่พบห้องสนทนา</h6>
          <p className='Placeholder__p'>
            กรุณาทำการเชื่อมต่อห้องสนทนากับบอทก่อนใช้งาน
          </p>
        </div>
      )}

      <ModalEditRoomName modalData={modalData} modalDataSet={modalDataSet} />
    </React.Fragment>
  )
}

SourcesIndex.propTypes = {}

export default SourcesIndex

const NotiField = ({data, type, onChange, index, style}) => {
  const id = React.useMemo(() => `${index}|${type}`, [index])

  return (
    <div className='Table__col Table__col--flex' style={style}>
      {(get(data, 'user.has_email') && type === 'enable_email_noti') ||
      type === 'enable_line_noti' ? (
        <>
          <span>{get(data, type) ? 'เปิด' : 'ปิด'}</span>
          <div className='Switch'>
            <input
              type='checkbox'
              id={id}
              checked={get(data, type) ?? false}
              onChange={onChange}
            />
            <label htmlFor={id} />
          </div>
        </>
      ) : (
        <div
          style={{
            color: '#4EAAF6',
            fontSize: '12px',
            padding: '8px 12px',
            background: '#EDF8FF',
            margin: '16px 0',
            border: '1px solid #B7E3FF',
            borderRadius: '3px',
          }}
        >
          กรุณาแจ้งเจ้าของห้องให้เพิ่มอีเมลเข้าไปในระบบ
        </div>
      )}
    </div>
  )
}
