import React from 'react'
import ReactDOM from 'react-dom'
import {get} from 'lodash'
import className from 'classnames'
import DatePicker from 'react-datepicker'
import {useFormik} from 'formik'

import {axios} from '@lib/helpers'

const tomorrow = new Date()
tomorrow.setDate(tomorrow.getDate() + 1)

const ModalRoomSharing = (props) => {
  const {modalData, modalDataSet} = props
  const {data: source} = modalData

  const [isLoading, setIsLoading] = React.useState(false)
  const [links, setLinks] = React.useState([])
  const [date, setDate] = React.useState(tomorrow)

  const {handleChange, handleSubmit, values} = useFormik({
    initialValues: {
      password: '',
      downloadable: false,
    },
    onSubmit: async (values) => {
      setIsLoading(true)
      const {password, downloadable} = values

      try {
        await axios.post(
          `/sources/${get(source, 'source_type_name')}/${get(
            source,
            'source_id'
          )}/shared_links`,
          {
            password,
            downloadable,
            expiry: date,
          }
        )
        await getLinks()
      } catch (err) {
        console.error(err)
      } finally {
        setIsLoading(false)
      }
    },
  })

  React.useEffect(() => {
    if (gon.current_user_id === get(source, 'user.id')) {
      getLinks()
    }
  }, [source])

  const getLinks = () => {
    axios
      .get(
        `/sources/${get(source, 'source_type_name')}/${get(
          source,
          'source_id'
        )}/shared_links`
      )
      .then((res) => setLinks(get(res, 'data.links', [])))
  }

  const handleCopy = (token) => {
    const input = document.getElementById(token)

    if (input) {
      input.select()
      document.execCommand('copy')
    }
  }

  const handleDeleteLink = async (token) => {
    try {
      await axios.delete(
        `/sources/${get(source, 'source_type_name')}/${get(
          source,
          'source_id'
        )}/shared_links/${token}`
      )
      await getLinks()
    } catch (err) {
      console.error(err)
    }
  }

  return ReactDOM.createPortal(
    <div
      className={className('Modal RoomSharing', {
        'Modal--active': get(modalData, 'isActive'),
      })}
    >
      <a
        className='Modal__overlay'
        onClick={() => {
          modalDataSet({
            ...modalData,
            isActive: false,
          })
        }}
      />

      <div className='Modal__wrapper'>
        <div className='RoomSharing__left'>
          <div className='RoomSharing__left__head'>
            <div className='RoomSharing__left__head__svg' />
            <p className='RoomSharing__left__head__p'>
              <span className='RoomSharing__left__head__p__span'>
                สร้างลิงก์เชื่อมต่อ
              </span>
              <br />
              คุณสามารถส่งคำเชิญจากลิงก์ด้านล่าง ถึงเพื่อนร่วมงาน ลูกค้า
              หรือกลุ่มสนทนาของคุณสามารถตั้งค่าง่ายๆ สำหรับการจำกัดสิทธิ์เข้าถึง
            </p>
          </div>

          <div className='RoomSharing__left__setting'>
            <p className='RoomSharing__left__setting__p'>
              ตั้งค่าการแชร์ห้องสนทนา
            </p>

            <form onSubmit={handleSubmit}>
              <div className='RoomSharing__left__setting__Grid'>
                <label className='RoomSharing__left__setting__label'>
                  ตั้งรหัสผ่าน
                </label>
                <input
                  className='RoomSharing__left__setting__input'
                  type='password'
                  name='password'
                  onChange={handleChange}
                  placeholder='ไม่บังคับ'
                />

                <label className='RoomSharing__left__setting__label'>
                  ข้อจำกัดใน
                  <br />
                  การใช้ห้องสนทนา
                </label>

                <select
                  name='downloadable'
                  className='RoomSharing__left__setting__select'
                  onChange={handleChange}
                  value={values.downloadable}
                >
                  <option value='false'>อ่านอย่างเดียว</option>
                  <option value='true'>อ่านและโหลด</option>
                </select>

                <label className='RoomSharing__left__setting__label'>
                  ระยะเวลา
                  <br />
                  อายุของลิงก์ห้องสนทนา
                </label>
                <DatePicker
                  selected={date}
                  onChange={(date) => setDate(date)}
                  className='RoomSharing__left__setting__datepicker'
                  dateFormat='dd/MM/yyyy'
                  portalId='root-portal'
                />

                <div className='RoomSharing__left__setting__Button-wrapper'>
                  <button
                    className='Button Button--trans'
                    type='button'
                    onClick={() => {
                      modalDataSet((state) => ({...state, isActive: false}))
                    }}
                  >
                    ยกเลิก
                  </button>
                  <button className='Button' type='submit' disabled={isLoading}>
                    Generate Link
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className='RoomSharing__right'>
          <a
            className='Modal__x'
            onClick={() => {
              modalDataSet({
                ...modalData,
                isActive: false,
              })
            }}
          />
          <div className='Table'>
            <div className='Table__row'>
              {['URL', 'รหัสผ่าน', 'การเข้าถึง', ''].map((head) => (
                <div className='Table__col Table__col--header' key={head}>
                  {head}
                </div>
              ))}
            </div>
            {links.map((item) => {
              const {token, password_required, downloadable, expiry} = item

              return (
                <div className='Table__row' key={token}>
                  <div className='Table__col'>
                    <div className='RoomSharing__right__url-wrapper'>
                      <div className='RoomSharing__right__url-wrapper__div'>
                        <input
                          className='RoomSharing__right__link'
                          value={`${window.location.origin}/views/${token}`}
                          id={token}
                          readOnly
                        />

                        <button
                          className='RoomSharing__right__button'
                          onClick={() => handleCopy(token)}
                        >
                          <div className='RoomSharing__right__button--copy' />
                        </button>
                      </div>

                      <p className='RoomSharing__right__url-wrapper__p'>
                        หมดอายุ {expiry}
                      </p>
                    </div>
                  </div>

                  <div className='Table__col'>
                    {password_required ? (
                      <input
                        value='********'
                        type='password'
                        className='RoomSharing__right__input'
                        readOnly
                      />
                    ) : (
                      <span className='RoomSharing__right__no-password'>
                        ไม่มีรหัส
                      </span>
                    )}
                  </div>

                  <div className='Table__col'>
                    {downloadable ? 'อ่านและโหลด' : 'อ่านอย่างเดียว'}
                  </div>

                  <div className='Table__col'>
                    <button
                      className='RoomSharing__right__button'
                      onClick={() => handleDeleteLink(token)}
                    >
                      <div className='RoomSharing__right__button--bin' />
                    </button>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>,
    document.querySelector('#modals')
  )
}

export default ModalRoomSharing
