import React from 'react'
import PropTypes from 'prop-types'
import {get} from 'lodash'
import className from 'classnames'
import {motion} from 'framer-motion'

const Hamburger = ({klass, isActive, isActiveSet}) => {
  return (
    <motion.a
      animate={isActive ? 'open' : 'closed'}
      onClick={() => {
        isActiveSet(!isActive)
      }}
      className={klass}
    >
      <svg width='24' height='24' viewBox='0 0 24 24'>
        <motion.rect
          width='21'
          height='2'
          y='4'
          rx='1'
          variants={{
            closed: {rotate: 0, translateX: 0, translateY: 0},
            open: {rotate: -45, translateX: -1, translateY: -1},
          }}
          style={{
            originX: '21px',
            originY: '5px',
            rotate: 0,
            translateX: 0,
            translateY: 0,
          }}
        />

        <motion.rect
          width='15'
          height='2'
          x='6'
          y='11'
          rx='1'
          variants={{
            closed: {opacity: 1},
            open: {opacity: 0, transition: {duration: 0.1}},
          }}
          initial={{opacity: 1}}
        />

        <motion.rect
          width='21'
          height='2'
          y='18'
          rx='1'
          variants={{
            closed: {rotate: 0, translateX: 0},
            open: {rotate: 45, translateX: -1},
          }}
          style={{originX: '21px', originY: '19px', rotate: 0, translateX: 0}}
        />
      </svg>
    </motion.a>
  )
}

Hamburger.propTypes = {}

export default Hamburger
