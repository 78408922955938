import {get, isEmpty} from 'lodash'

import {useToast} from '@datawowio/payment-ui'

import {axios, getErrorMsg} from '@lib/helpers'

import {useOrderPageContext} from './store'

export const useOrderPageServices = () => {
  const {
    hostUrl,
    partnerCode,
    setRawAddresses,
    packageData,
    selectedAddress,
    recurringType,
    addressType,
  } = useOrderPageContext()

  const toast = useToast()

  return {
    createAddress: async (values, payload = {}) => {
      const {onSuccess = () => {}} = payload

      try {
        const newData = await createAddress({...values, addressType})

        setRawAddresses(newData)
        onSuccess()
        toast({
          title: 'Address Created.',
          status: 'success',
          position: 'top-right',
        })
      } catch (error) {
        toast({
          title: getErrorMsg(error),
          status: 'error',
          position: 'top-right',
          isClosable: true,
        })
      }
    },
    updateAddress: async (values, payload = {}) => {
      const {onSuccess = () => {}} = payload

      try {
        const newData = await updateAddress({...values, addressType})

        setRawAddresses(newData)
        onSuccess()
        toast({
          title: 'Address Updated.',
          status: 'success',
          position: 'top-right',
        })
      } catch (error) {}
    },
    deleteAddress: async (id) => {
      try {
        const newData = await deleteAddress(id)

        setRawAddresses(newData)
        toast({
          title: 'Address Deleted.',
          status: 'success',
          position: 'top-right',
        })
      } catch (error) {
        toast({
          title: getErrorMsg(error),
          status: 'error',
          position: 'top-right',
          isClosable: true,
        })
      }
    },
    saveInformation: async () => {
      try {
        const data = {
          package: packageData.id,
          addressId: selectedAddress,
          recurring: recurringType,
        }

        const token = await saveInformation(data)

        toast({
          title: 'Payment Information saved.',
          status: 'success',
          position: 'top-right',
        })

        const params = {
          token,
          ...(!isEmpty(partnerCode) && {partner: partnerCode}),
        }

        const searchParams = new URLSearchParams(params)

        window.location.href = `${hostUrl}/payments/purchases?${searchParams.toString()}`
      } catch (error) {
        toast({
          title: getErrorMsg(error),
          status: 'error',
          position: 'top-right',
          isClosable: true,
        })
      }
    },
  }
}

export const createAddress = (values) => {
  return axios
    .post('/payments/addresses', {
      name: values.name,
      details: values.address,
      primary: values.primary,
      taxpayer_id: values.taxId,
      taxpayer_type: values.addressType,
    })
    .then(({data}) => get(data, 'addresses', []))
}

export const updateAddress = (values) => {
  return axios
    .put(`/payments/addresses/${values.id}`, {
      name: values.name,
      details: values.address,
      primary: values.primary,
      taxpayer_id: values.taxId,
      taxpayer_type: values.addressType,
    })
    .then(({data}) => get(data, 'addresses', []))
}

export const deleteAddress = (id) => {
  return axios
    .delete(`/payments/addresses/${id}`)
    .then(({data}) => get(data, 'addresses', []))
}

export const saveInformation = (data) => {
  return axios
    .post('/payments', {
      address_id: data.addressId,
      package: data.package,
      recurring: data.recurring,
    })
    .then(({data}) => get(data, 'token'))
}
