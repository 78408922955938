import React from 'react'
import {get} from 'lodash'
import className from 'classnames'
import * as Cookies from 'js-cookie'

import img0 from '@images/app/Guide__ul__li__img--0.svg'
import img1 from '@images/app/Guide__ul__li__img--1.png'
import img2 from '@images/app/Guide__ul__li__img--2.svg'
import img3 from '@images/app/Guide__ul__li__img--3.svg'

const KEY = '_chat_storage_guide_hide'

const Guide = ({token}) => {
  const [isHidden, isHiddenSet] = React.useState(Cookies.get(KEY) == 1)

  return (
    <div
      className={className('Guide', {
        'Guide--hidden': isHidden,
      })}
    >
      <div className='Guide__header'>
        <h3 className='Guide__h3'>
          วิธีการเชื่อมต่อ Line Group กับแพลตฟอร์ม JotT
        </h3>

        <a
          className='Guide__close'
          onClick={() => {
            Cookies.set(KEY, 1, {expires: 365 * 10})
            isHiddenSet(true)
          }}
        >
          ซ่อนคู่มือการใช้งาน
        </a>

        <a
          className='Guide__open'
          onClick={() => {
            Cookies.remove(KEY)
            isHiddenSet(false)
          }}
        />
      </div>

      <div className='Guide__content'>
        <ul className='Guide__ul'>
          {[
            {
              img: img0,
              label: 'เข้าสู่ระบบผ่าน LINE',
            },
            {
              img: img1,
              label: 'เพิ่มเพื่อน โดยค้นหา “@jott” หรือ สแกน QRCODE ด้านบน',
            },
            {
              img: img2,
              label: 'เพิ่มน้องบอทเข้าไปยัง กรุ๊ปไลน์ของคุณ',
            },
            {
              img: img3,
              label: (
                <React.Fragment>
                  คลิ๊กที่ลิงค์ด้านล่างบทสนทนา พิมพ์ <code>{token}</code>{' '}
                  แล้วกดเชื่อมต่อ
                </React.Fragment>
              ),
            },
          ].map((x, i) => (
            <li key={i} className='Guide__ul__li'>
              <img
                className='Guide__ul__li__img'
                src={get(x, 'img')}
                style={
                  i == 1
                    ? {
                        maxWidth: '114px',
                        maxHeight: '114px',
                      }
                    : null
                }
              />

              <span className='Guide__ul__li__span'>
                {i + 1}. {get(x, 'label')}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

Guide.propTypes = {}

export default Guide
