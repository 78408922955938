import React from 'react'

import Guide from '@components/molecules/Guide'
import Accordion from '@components/molecules/Accordion'
import ExternalLink from '@components/molecules/ExternalLink'

const questions = [
  {
    q: 'JotT คืออะไร ?',
    a:
      'JotT คือ LINE Bot ที่ช่วยบันทึกบทสนทนา คัดลอกไฟล์ไปเก็บไว้บนคลาวด์เพื่อให้ข้อมูลของคุณไม่หายอีกต่อไป อีกทั้งสามารถค้นหา ย้อนดูประวัติและบทสนทนาได้อย่างง่ายดาย',
  },
  {
    q: 'ฉันจะเชื่อมต่อ JotT Line bot กับ Group chat ได้อย่างไร ?',
    a: (
      <React.Fragment>
        <p>ไม่ยากเลย เพียงแค่ 3 ขั้นตอนเท่านั้น</p>
        <ol>
          <li>
            เปิด Application Line แล้วไปยัง หน้าหลัก &gt; เพิ่มเพื่อน &gt; ค้นหา
            &quot;@Jott&quot; และกด เพิ่มเพื่อน
          </li>
          <li>
            ไปยังแชทกลุ่ม หรือ Group ที่ต้องการ แล้วเชิญ &quot;น้องจด -
            JotT.ai&quot;
          </li>
          <li>
            เมื่อ &quot;น้องจด - JotT.ai&quot; เข้าห้องแล้ว กด Link จากข้อความ
            และกรอก Key ที่ได้จาก Platform
          </li>
        </ol>
        <p>
          เพียงเท่านี้ น้องจด
          ก็พร้อมจัดเก็บข้อความและไฟล์ให้กับห้องแชทของคุณแล้ว
        </p>
      </React.Fragment>
    ),
  },
  {
    q:
      'เพื่อนของฉันอยู่ในห้องแชทด้วยเหมือนกัน แต่ห้องแชทไม่ปรากฏในแพลตฟอร์ม ฉันจะทำอย่างไรดี',
    a:
      'ไม่แน่ว่าเพื่อนของคุณยังไม่ได้พิมพ์ "จดที" ลงไปในห้องแชท เมื่อเพื่อนของคุณพิมพ์ "จดที" ห้องแชทจะปรากฏให้เห็นในแพลตฟอร์ม',
  },
  {
    q: 'หากฉันมีคำถามหรือFeedback กับ JotT ฉันสามารถติดต่อได้ที่ใด',
    a: 'คุณสามารถติดต่อเราผ่านทางอีเมล์ sales@mplus.co.th ได้เลย',
  },
  {
    q: 'ไฟล์อะไรบ้างที่ JotT สามารถสำรองเก็บได้ ?',
    a: (
      <React.Fragment>
        เราสามารถจัดเก็บไฟล์รูปภาพ วิดีโอ เอกสาร หรือไฟล์เสียง ตามข้อกำหนดของ
        Line คุณสามารถดูรายละเอียดไฟล์ที่ Line สามารถเก็บได้เพิ่มเติมที่นี่{' '}
        <ExternalLink href='https://help.line.me/line/win/categoryId/10000336/pc?lang=th'>
          https://help.line.me/line/win/categoryId/10000336/pc?lang=th
        </ExternalLink>
      </React.Fragment>
    ),
  },
]

const FaqIndex = (props) => {
  const {token} = props

  const renderContent = (data) => {
    return data.map(({q, a}) => <Accordion key={q} head={q} body={a} />)
  }

  return (
    <React.Fragment>
      <div className='Header'>
        <h2 className='Header__h2'>
          <span className='Header__breadcrumb__current'>คู่มือการใช้งาน</span>
        </h2>
      </div>
      <Guide token={token} />

      <div className='FaqIndex'>
        <div className='FaqIndex__title'>คำถามที่พบบ่อย</div>
        {renderContent(questions)}
      </div>
    </React.Fragment>
  )
}

export default FaqIndex
