import React from 'react'
import {get, map, isEmpty, flatten, entries, isUndefined} from 'lodash'
import {useFormik} from 'formik'

import {axios} from '@lib/helpers'

import Modal from '@components/molecules/Modal'

const ProfilesShow = ({current_user, unconfirmed_email}) => {
  const [modalData, modalDataSet] = React.useState({
    isActive: false,
  })
  const [errors, errorsSet] = React.useState()
  const [isLoading, isLoadingSet] = React.useState(false)

  const {handleChange, handleSubmit, values} = useFormik({
    initialValues: {
      newsletter: current_user.newsletter_consent,
      payment: current_user.payment_consent,
      alert: current_user.alert_consent,
    },
    validateOnChange: false,
    onSubmit: async (values) => {
      isLoadingSet(true)

      const {newsletter, payment, alert} = values

      const formData = new FormData()
      formData.append('newsletter_consent', newsletter)
      formData.append('payment_consent', payment)
      formData.append('alert_consent', alert)

      try {
        await axios
          .patch(`/profile`, formData)
          .then((res) => {
            window.location.reload()
          })
          .catch((e) => {
            isLoadingSet(false)
            errorsSet(get(e, 'response.data.errors'))
          })
      } catch (err) {
        console.error(err)
      }
    },
  })

  return (
    <div className='ProfilesShow'>
      <div className='container'>
        <div className='Header'>
          <h2 className='Header__h2'>
            <span className='Header__breadcrumb__current'>ข้อมูลส่วนตัว</span>
          </h2>
        </div>

        <div className='ProfilesShow__form'>
          <img
            className='ProfilesShow__form__img'
            src={get(current_user, 'image_url')}
          />

          <label className='Form__label'>
            <span className='Form__label__span'>LINE Display Name</span>

            <input
              type='text'
              className='Input'
              disabled
              defaultValue={get(current_user, 'name')}
            />
          </label>

          <div className='Form__label'>
            <div className='Form__label__row'>
              <span className='Form__label__span'>
                อีเมล (สำหรับใช้ในการติดต่อ และ ขอรับใบเสร็จ จากทางผู้พัฒนา)
              </span>

              <a
                className='Form__label__a'
                onClick={() => {
                  modalDataSet({
                    isActive: true,
                  })
                }}
              >
                แก้ไข
              </a>
            </div>

            <input
              type='text'
              className='Input'
              defaultValue={get(current_user, 'email')}
              name='email'
              disabled
            />
          </div>

          <form onSubmit={handleSubmit} className='ProfilesShow__consent-form'>
            <div className='ProfilesShow__consent-form__wrapper'>
              <p>
                <input
                  type='checkbox'
                  name='newsletter'
                  id='newsletter'
                  checked={values.newsletter}
                  onChange={handleChange}
                />
                <label htmlFor='newsletter'>
                  ฉันยินยอมและอนุญาตให้ผู้ให้บริการส่งอีเมลเพื่อรับจดหมายข่าวหรืออัปเดตซอฟต์แวร์
                </label>
              </p>
              <p>
                <input
                  type='checkbox'
                  name='payment'
                  id='payment'
                  checked={values.payment}
                  onChange={handleChange}
                />
                <label htmlFor='payment'>
                  ฉันยินยอมและอนุญาตให้ผู้ให้บริการส่งอีเมลสำหรับการเรียกเก็บเงินหรือประกาศสำคัญทางอีเมล
                </label>
              </p>
              <p>
                <input
                  type='checkbox'
                  name='alert'
                  id='alert'
                  checked={values.alert}
                  onChange={handleChange}
                />
                <label htmlFor='alert'>
                  ฉันยินยอมและอนุญาตให้ผู้ให้บริการติดต่อผ่านบัญชีทางการของ LINE
                  ในกรณีที่มีการประกาศที่สำคัญ
                </label>
              </p>
            </div>

            <button
              className='Button Button--large'
              disabled={isLoading}
              type='submit'
            >
              บันทึก
            </button>
          </form>
        </div>
      </div>

      <Modal modalData={modalData} modalDataSet={modalDataSet}>
        <form
          className='Modal__form'
          onSubmit={(e) => {
            e.preventDefault()

            const $els = get(e, 'target.elements')
            const formData = new FormData()

            const inputs = map($els, (x) => {
              return {
                name: get(x, 'name'),
                value: get(x, 'name') == 'logo' ? uploadFile : get(x, 'value'),
              }
            }).filter(
              (x) => !isEmpty(get(x, 'name')) && !isUndefined(get(x, 'value'))
            )

            inputs.forEach((x) => {
              formData.append(get(x, 'name'), get(x, 'value'))
            })

            isLoadingSet(true)

            axios
              .patch(`/profile`, formData)
              .then((res) => {
                window.location.reload()
              })
              .catch((e) => {
                isLoadingSet(false)
                errorsSet(get(e, 'response.data.errors'))
              })
          }}
        >
          <h2 className='Modal__h2'>แก้ไขหรือเปลี่ยนอีเมล</h2>

          <label className='Form__label'>
            <input
              type='text'
              className='Input'
              placeholder='อีเมลใหม่'
              name='email'
              defaultValue={unconfirmed_email}
              required
            />
          </label>

          {!isEmpty(errors) && (
            <ul className='Form__errors'>
              {flatten(entries(errors).map((x) => get(x, '1'))).map((x, i) => (
                <li key={i} className='Form__errors__li'>
                  {x}
                </li>
              ))}
            </ul>
          )}

          <p className='Modal__p'>
            เมื่อทำการเปลี่ยนอีเมลเป็นใหม่แล้ว กรุณาตรวจสอบที่{' '}
            <strong>“กล่องจดหมาย</strong>”
            ของคุณและกดยืนยันอีเมลเพื่อเสร็จสิ้นขั้นตอน
            มิเช่นนั้นอีเมลใหม่จะไม่ถูกแทนที่
          </p>

          <button className='Button Button--large' disabled={isLoading}>
            ยืนยัน
          </button>

          <div className='ProfilesShow__resend-email'>
            ยังไม่ได้รับอีเมลเพื่อยืนยัน? <a>ส่งอีเมลอีกครั้ง</a>
          </div>
        </form>
      </Modal>
    </div>
  )
}

ProfilesShow.propTypes = {}

export default ProfilesShow
