import {get} from 'lodash'
import {useToast} from '@datawowio/payment-ui'

import {axios, getErrorMsg} from '@lib/helpers'

import {usePurchasesContext, PAYMENT_METHOD} from './store'

export const usePurchasesServices = () => {
  const {
    setRawCreditCards,
    onOpenTransferModal,
    onOpenQrModal,
    token,
    paymentMethod,
    hostUrl,
    partner,
    setPartner,
    email,
  } = usePurchasesContext()
  const toast = useToast()

  return {
    addNewCard: async (values, payload = {}) => {
      const {onSuccess = () => {}} = payload

      try {
        const newData = await createCard(values)

        setRawCreditCards(newData)
        onSuccess()
        toast({
          title: 'Credit Card Created.',
          status: 'success',
          position: 'top-right',
        })
      } catch (error) {
        toast({
          title: error?.message || getErrorMsg(error),
          status: 'error',
          position: 'top-right',
          isClosable: true,
        })
      }
    },
    deleteCard: async (id) => {
      try {
        const newData = await deleteCard(id)
        setRawCreditCards(newData)
        toast({
          title: 'Credit Card Deleted.',
          status: 'success',
          position: 'top-right',
        })
      } catch (error) {
        toast({
          title: getErrorMsg(error),
          status: 'error',
          position: 'top-right',
          isClosable: true,
        })
      }
    },
    setPrimaryCard: async (id) => {
      try {
        const newData = await setPrimaryCard(id)
        setRawCreditCards(newData)
        toast({
          title: 'Primary card has changed.',
          status: 'success',
          position: 'top-right',
        })
      } catch (error) {
        toast({
          title: getErrorMsg(error),
          status: 'error',
          position: 'top-right',
          isClosable: true,
        })
      }
    },
    onSubmitPayment: () => {
      const {CREDIT, TRANSFER, QR} = PAYMENT_METHOD

      const handleSubmitByType = {
        [CREDIT]: async () => {
          try {
            const res = await createChargeRecurring({
              cardId: paymentMethod.payload,
              token: token,
              partnerCode: partner.code,
              email,
            })

            window.location.href =
              res?.data?.auth_uri ??
              `${hostUrl}/payments/success?token=${token}`
          } catch (error) {
            toast({
              title: getErrorMsg(error),
              status: 'error',
              position: 'top-right',
              isClosable: true,
            })
          }
        },
        [TRANSFER]: () => {
          onOpenTransferModal()
        },
        [QR]: () => {
          onOpenQrModal()
        },
      }[paymentMethod.type]

      handleSubmitByType()
    },
    onCheckPartner: async () => {
      try {
        setPartner({...partner, checking: true, valid: null})
        const data = await checkPartner(partner.code)
        setPartner({
          ...partner,
          checking: false,
          valid: data,
        })
      } catch (error) {
        setPartner({
          ...partner,
          checking: false,
          valid: false,
        })
      }
    },
  }
}

export const createCard = async (values) => {
  const data = {
    name: values.name,
    number: values.cardNumber,
    expiration_month: values.expMonth,
    expiration_year: values.expYear,
    security_code: values.cardCvv,
    default_card: values.primary,
    brand: values.cardType,
  }

  const response = await new Promise((resolve, reject) => {
    Omise.createToken('card', data, function (statusCode, response) {
      if (statusCode === 200) {
        resolve(response)
      } else {
        reject(response)
      }
    })
  })

  data.card_token = response.id

  return axios.post('/payments/credit_cards', data).then(({data}) => {
    return get(data, 'credit_cards')
  })
}

export const setPrimaryCard = (id) => {
  return axios
    .put(`/payments/credit_cards/${id}`, {
      default_card: true,
    })
    .then(({data}) => {
      return get(data, 'credit_cards')
    })
}

export const deleteCard = (id) => {
  return axios.delete(`/payments/credit_cards/${id}`).then(({data}) => {
    return get(data, 'credit_cards')
  })
}

export const createChargeRecurring = ({cardId, token, partnerCode, email}) => {
  return axios.post(`/payments/credit_cards/recurrings`, {
    token,
    id: cardId,
    partner_code: partnerCode,
    email,
  })
}

export const saveConfirmTransfer = (formData) => {
  return axios.post('/payments/transfers', formData).then(({data}) => {
    return get(data, 'success')
  })
}

export const getQrSource = ({token}) => {
  return axios.get(`/payments/qr_codes?token=${token}`).then(({data}) => {
    return data
  })
}
export const getQrPaymentStatus = ({refNo}) => {
  return axios.get(`/payments/qr_codes/${refNo}`).then(({data}) => {
    return get(data, 'qr_code')
  })
}

export const checkPartner = (partnerCode) => {
  return axios.get(`/partners/${partnerCode}`).then(({data}) => {
    return data
  })
}
