import React from 'react'
import className from 'classnames'
import * as queryString from 'query-string'
import {
  CgPushChevronLeft,
  CgChevronLeft,
  CgChevronRight,
  CgPushChevronRight,
} from 'react-icons/cg'

const ITEM_PER_PAGE = 10

const Pagination = ({total_files, current_page, total_pages}) => {
  const start = 1 + ITEM_PER_PAGE * (current_page - 1)
  const last =
    ITEM_PER_PAGE * current_page < total_files
      ? ITEM_PER_PAGE * current_page
      : total_files

  const href = (page) => {
    const parsed = queryString.parse(location.search)
    location.search = queryString.stringify({...parsed, page})
  }

  return (
    <div className='Pagination'>
      <div className='Pagination__nav'>
        <div className='Pagination__nav__detail'>
          {start > 0 ? start : 0} - {last} of {total_files}
        </div>
        <a
          className={className('Pagination__nav__a', {
            disabled: current_page === 1,
          })}
          onClick={() => href(1)}
          disabled={current_page === 1}
        >
          <CgPushChevronLeft />
        </a>
        <a
          className={className('Pagination__nav__a', {
            disabled: current_page === 1,
          })}
          onClick={() => href(current_page - 1)}
          disabled={current_page === 1}
        >
          <CgChevronLeft />
        </a>
        <div className='Pagination__nav__pages'>
          Page
          <input
            className='Pagination__nav__pages__input'
            defaultValue={current_page}
            placeholder='1'
            type='number'
            min={1}
            max={total_pages}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                href(
                  e.target.value < 0
                    ? 1
                    : e.target.value > total_pages
                    ? total_pages
                    : e.target.value
                )
              }
            }}
            onClick={(e) => {
              href(e.target.value)
            }}
          />
          / {total_pages}
        </div>
        <a
          className={className('Pagination__nav__a', {
            disabled: current_page === total_pages,
          })}
          onClick={() => href(current_page + 1)}
          disabled={current_page === total_pages}
        >
          <CgChevronRight />
        </a>
        <a
          className={className('Pagination__nav__a', {
            disabled: current_page === total_pages,
          })}
          onClick={() => href(total_pages)}
          disabled={current_page === total_pages}
        >
          <CgPushChevronRight />
        </a>
      </div>
    </div>
  )
}

export default Pagination
