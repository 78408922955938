import React from 'react'
import PropTypes from 'prop-types'
import {get, map, isEmpty, flatten, entries, isUndefined, each} from 'lodash'
import className from 'classnames'

import {axios} from '@lib/helpers'

const LINK_COMPLETE_TEXT = 'เชื่อมต่อสำเร็จ'

const LinksIndex = ({liff_id, source_link_id, links, omise_public_key}) => {
  const [isActive, isActiveSet] = React.useState(false)
  const [errors, errorsSet] = React.useState()
  const [isLoading, isLoadingSet] = React.useState(false)

  React.useEffect(() => {
    liff
      .init({liffId: liff_id})
      .catch((e) => {
        errorsSet(get(e, 'messages'))
      })
      .finally(() => {
        isActiveSet(true)
      })
  }, [])

  if (!isActive) {
    return (
      <p className='LinksIndex__loading'>
        {isEmpty(errors) ? 'Loading …' : errors}
      </p>
    )
  }

  if (isActive && !liff.isInClient()) {
    return (
      <p className='LinksIndex__loading'>
        To link your LINE group with your account, please open this link inside
        your LINE group
      </p>
    )
  }

  const sendFailResponse = (status, raw) => {
    const context = liff.getContext()
    const source = getSource(context, source_link_id)
    axios.post('/fail_response', {
      status,
      raw,
      link_id: source.link_id,
      uid: get(context, 'userId'),
    })
  }

  const sendLiffMessage = (sourceName, sourceId) => {
    liff
      .sendMessages([
        {
          type: 'text',
          text:
            'สำหรับเพื่อนๆ ในกลุ่ม เริ่มต้นง่ายๆ เพียงพิมพ์ “จดที” ' +
            'เท่านี้ข้อความก็จะเริ่มทำการถูกบันทึกแล้วครับ \n\n' +
            'สามารถดูประวัติสนทนา ไฟล์ต่างๆ หรือรูปภาพ โดยเข้าที่ลิงค์นี้ \n\n' +
            `https://jott.ai/sources/${sourceName}/${sourceId}`,
        },
      ])
      .then((e) => {
        liff.closeWindow()
      })
  }

  const waitAndReSendMessage = (token, source, count) => {
    // Wait 5 seconds and check connection between user and source
    setTimeout(() => {
      axios
        .post('/links/check_connection', {
          token,
          link_id: source.link_id,
        })
        .then((e) => {
          if (e.data) {
            try {
              // If connection is established, send message to user via LIFF
              sendLiffMessage(source.name, get(e, 'data.source_id'))
            } catch (e) {
              if (count >= 2) {
                // If failed for 3 times, send complete message to user directly
                isLoadingSet(false)
                errorsSet([LINK_COMPLETE_TEXT])
              } else {
                // If liff request is failed, try again
                waitAndReSendMessage(token, source, count + 1)
              }
            }
          } else {
            // If connection is not established, send error message
            isLoadingSet(false)
            errorsSet(['มีบางอย่างผิดพลาด ไม่สามารถเชื่อมต่อได้'])
          }
        })
    }, 5000)
  }

  return (
    <form
      className='LinksIndex'
      disabled={isLoading}
      onSubmit={(e) => {
        e.preventDefault()

        const $els = get(e, 'target.elements')

        const context = liff.getContext()
        const source = getSource(context, source_link_id)

        isLoadingSet(true)

        axios
          .post('/links', {
            token: get($els, 'token.value'),
            ...source,
          })
          .then((res) => {
            sendLiffMessage(source.name, get(res, 'data.source_id'))
          })
          .catch((e) => {
            const status = get(e, 'response.status').toString()
            const data = get(e, 'response.data')

            // Send fail response when LIFF failed or conflict
            if (status != '404' && !(status == '422' && data)) {
              sendFailResponse(status, get(e, 'response'))
            }

            if (['401', '403', '500'].includes(status)) {
              waitAndReSendMessage(get($els, 'token.value'), source, 0)
            } else {
              isLoadingSet(false)
              if (status == '409') {
                errorsSet(['ห้องนี้ถูกเชื่อมต่อไปแล้ว'])
              } else if (status == '422' && data) {
                errorsSet(data)
              } else {
                errorsSet(['มีบางอย่างผิดพลาด ไม่สามารถเชื่อมต่อได้'])
              }
            }
          })
      }}
    >
      <p className='LinksIndex__p'>
        กรุณา<strong>กรอกรหัสตัวเลข 6 ตัว</strong>ที่ได้จากแพลตฟอร์ม
        เพื่อเชื่อมต่อน้องบอท JotT กับ Line group
      </p>

      <input
        type='tel'
        className='Input'
        placeholder='รหัสตัวเลข'
        name='token'
      />

      {!isEmpty(errors) && (
        <React.Fragment>
          <ul className='Form__errors'>
            {flatten(entries(errors).map((x) => get(x, '1'))).map((x, i) => (
              <li
                key={i}
                className={
                  x === LINK_COMPLETE_TEXT
                    ? 'Form__errors__complete'
                    : 'Form__errors__li'
                }
              >
                {x}
              </li>
            ))}
          </ul>
        </React.Fragment>
      )}

      <button className='ButtonLanding'>
        {isLoading ? 'Loading …' : 'เชื่อมต่อห้องสนทนา'}
      </button>

      <small className='LinksIndex__small'>
        <a
          href={get(links, 'terms_path')}
          target='_blank'
          rel='nofollow noopener noreferrer'
        >
          Terms of use
        </a>{' '}
        and{' '}
        <a
          href={get(links, 'privacy_path')}
          target='_blank'
          rel='nofollow noopener noreferrer'
        >
          Privacy policy
        </a>
      </small>
    </form>
  )
}

LinksIndex.propTypes = {}

export default LinksIndex

const getSource = (context, source_link_id) => {
  if (get(context, 'groupId')) {
    return {
      link_id: source_link_id,
      source_type: 'group_type',
      name: 'group',
    }
  } else if (get(context, 'roomId')) {
    return {
      link_id: source_link_id,
      source_type: 'room_type',
      name: 'room',
    }
  } else {
    return {}
  }
}
