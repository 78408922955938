import React from 'react'
import {get} from 'lodash'
import {useFormik} from 'formik'
import * as Yup from 'yup'

import {axios} from '@lib/helpers'

const ViewsShow = (props) => {
  const {token} = props

  const [error, setError] = React.useState(null)

  const {handleChange, handleSubmit, values, errors, touched} = useFormik({
    initialValues: {password: ''},
    validateOnChange: false,
    onSubmit: ({password}) => {
      axios
        .put(`/views/${token}`, {password})
        .then((res) => {
          const redirectTo = get(res, 'data.redirect_url')
          window.location = `${window.location.origin}/${redirectTo}`
        })
        .catch((err) => {
          setError(get(err, 'response.data.errors[0]'))
        })
    },
    validationSchema: Yup.object().shape({
      password: Yup.string().required('กรุณากรอกรหัสผ่าน'),
    }),
  })

  return (
    <div className='ViewsShow'>
      <form className='ViewsShow__form' onSubmit={handleSubmit}>
        <div className='ViewsShow__wrapper'>
          <div className='ViewsShow__form__svg' />
          <h3 className='ViewsShow__form__h3'>ห้องสนทนานี้ถูกเข้ารหัส</h3>
          <p className='ViewsShow__form__p'>
            เนื่องจากห้องสนทนานี้ได้ถูกเข้ารหัส กรุณากรอกรหัสผ่าน
            <br />
            เพื่อเข้าใช้งานห้องสนทนาดังกล่าว
          </p>
          <input
            className='Input ViewsShow__form__input'
            value={values.password}
            onChange={handleChange}
            name='password'
            placeholder='รหัสผ่านห้องสนทนา'
            type='password'
          />
          {errors.password && touched.password && (
            <p className='ViewsShow__form__error'>{errors.password}</p>
          )}
          {error && <p className='ViewsShow__form__error'>{error}</p>}
          <button className='Button Button--large' type='submit'>
            เข้าสู่ห้องสนทนา
          </button>
        </div>
      </form>
    </div>
  )
}

export default ViewsShow
