import React, {useState} from 'react'
// import {get} from 'lodash'
import {
  PaymentProvider,
  // prettyCardNumber,
  useDisclosure,
} from '@datawowio/payment-ui'
import {isEmpty, find} from 'lodash'

import {useTransformPackgeData} from '../shared/hooks'
import {theme, project} from '../shared/theme'
import {createContext} from '../createContext'

const [PurchasesContextProvider, usePurchasesContext] = createContext({
  name: 'PurchasesContext',
})

export {usePurchasesContext}

const initialPaymentMethod = {
  type: '',
  payload: '',
}

export const PAYMENT_METHOD = {
  CREDIT: 'credit',
  TRANSFER: 'transfer',
  QR: 'qr',
}

export const withPurchasesPage = (WrappedComponent) => (props) => {
  const [rawCreditCards, setRawCreditCards] = useState(props.credit_cards)
  const {
    isOpen: isOpenTransferModal,
    onOpen: onOpenTransferModal,
    onClose: onCloseTransferModal,
  } = useDisclosure()
  const {
    isOpen: isOpenQrModal,
    onOpen: onOpenQrModal,
    onClose: onCloseQrModal,
  } = useDisclosure()

  const packageData = useTransformPackgeData(
    props.package,
    props.recurring,
    props.address.taxpayer_type
  )

  const creditCards = useTransformCreditCards(rawCreditCards)

  if (!isEmpty(creditCards)) {
    initialPaymentMethod.type = PAYMENT_METHOD.CREDIT
    initialPaymentMethod.payload = find(creditCards, {primary: true})?.id || ''
  }

  const [paymentMethod, setPaymentMethod] = useState(initialPaymentMethod)
  const [partner, setPartner] = useState({
    code: props.partner ?? props.partner_code,
    checking: false,
    valid: props.partner || props.partner_code ? true : null,
  })

  const [email, setEmail] = useState(props.email)

  const contextValues = {
    hostUrl: props.host_url,
    creditCards,
    setRawCreditCards,
    packageData,
    setPaymentMethod,
    paymentMethod,
    isOpenTransferModal,
    onOpenTransferModal,
    onCloseTransferModal,
    isOpenQrModal,
    onOpenQrModal,
    onCloseQrModal,
    token: props.token,
    partner,
    setPartner,
    email,
    setEmail,
  }

  return (
    <PurchasesContextProvider value={contextValues}>
      <PaymentProvider project={project} theme={theme}>
        <WrappedComponent {...props}>{props.children}</WrappedComponent>
      </PaymentProvider>
    </PurchasesContextProvider>
  )
}

const useTransformCreditCards = (creditCardData) => {
  const transformedData = React.useMemo(() => {
    return creditCardData.map((creditData) => {
      return {
        id: creditData.id,
        primary: creditData.default_card,
        brand: creditData.brand.toLowerCase(),
        number: 'XXXXXXXXXXXX' + creditData.last_digits,
        status: creditData.status,
      }
    })
  }, [creditCardData])

  return transformedData
}
