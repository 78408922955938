import React, {useState} from 'react'
import {get} from 'lodash'
import className from 'classnames'
import moment from 'moment'
import * as queryString from 'query-string'
import {FileIcon, defaultStyles} from 'react-file-icon'

import Tabs from '@components/molecules/Tabs'
import SourcesShowHeader from '@components/molecules/SourcesShowHeader'
import ModalEditRemark from '@components/molecules/ModalEditRemark'
import Pagination from '@components/molecules/Pagination'
import SearchForm from '@components/molecules/SearchForm'

import {start_and_end} from '@lib/helpers'

const MediaIndex = ({
  source,
  media = [],
  meta = {total: 0, current_page: 0, total_pages: 0},
}) => {
  const {total, current_page, total_pages} = meta
  const [modalData, modalDataSet] = useState({
    isActive: false,
  })

  const href = (type) => {
    const parsed = queryString.parse(location.search)
    location.search = queryString.stringify({...parsed, type})
  }

  return (
    <>
      <div className='MediaIndex'>
        <SourcesShowHeader source={source} />

        <Tabs source={source} />
        <div className='MediaIndex__search'>
          {(() => {
            const qs = queryString.parse(window.location.search)

            const filters = [
              {
                label: 'รูปภาพ',
                key: 'image',
                icon: (
                  <svg width='21px' height='18px' viewBox='0 0 21 18'>
                    <g transform='translate(0, -1)'>
                      <path d='M2,1 C0.897,1 0,1.897 0,3 L0,13 C0,14.103 0.897,15 2,15 L15,15 C16.103,15 17,14.103 17,13 L17,3 C17,1.897 16.103,1 15,1 L2,1 Z M20,5 C19.448,5 19,5.448 19,6 L19,17 L5,17 C4.448,17 4,17.448 4,18 C4,18.552 4.448,19 5,19 L19,19 C20.105,19 21,18.105 21,17 L21,6 C21,5.448 20.552,5 20,5 Z M11.128906,7.35348668 C11.306156,7.3552656 11.482609,7.4372031 11.599609,7.5957031 L14.882812,12.046875 C15.171813,12.435875 14.89225,13 14.40625,13 L2.59375,13 C2.09975,13 1.8239062,12.41925 2.1289062,12.03125 L4.4433594,9.087891 C4.6763594,8.791891 5.1232812,8.786172 5.3632812,9.076172 L7.046875,11.105469 C7.289875,11.397469 7.7416563,11.388891 7.9726562,11.087891 L10.652344,7.5859375 C10.772344,7.4294375 10.951656,7.3517656 11.128906,7.35348668 Z' />
                    </g>
                  </svg>
                ),
              },
              {
                label: 'วิดีโอ',
                key: 'video',
                icon: (
                  <svg width='20px' height='18px' viewBox='0 0 20 18'>
                    <g transform='translate(0, -1)'>
                      <path d='M18,1 L2,1 C0.897,1 0,1.897 0,3 L0,17 C0,18.103 0.897,19 2,19 L18,19 C19.103,19 20,18.103 20,17 L20,3 C20,1.897 19.103,1 18,1 Z M7,13.306 L7,6.694 C7,6.162 7.575,5.828 8.037,6.092 L13.661,9.398 C14.127,9.664 14.127,10.335 13.661,10.601 L8.037,13.907 C7.575,14.172 7,13.838 7,13.306 Z' />
                    </g>
                  </svg>
                ),
              },
              {
                label: 'เสียง',
                key: 'audio',
                icon: (
                  <svg
                    width='20px'
                    height='18px'
                    viewBox='0 0 20 18'
                    style={{transform: 'translateY(-1px)'}}
                  >
                    <g transform='translate(0, -1)'>
                      <path d='M18,1 C19.103,1 20,1.897 20,3 L20,3 L20,17 C20,18.103 19.103,19 18,19 L18,19 L2,19 C0.897,19 0,18.103 0,17 L0,17 L0,3 C0,1.897 0.897,1 2,1 L2,1 Z M12,7 L10,7 C9.448,7 9,7.448 9,8 L9,8 L9,11 C7.895,11 7,11.895 7,13 C7,14.215 8.082937,15.175656 9.335938,14.972656 C10.318937,14.812656 11,13.888578 11,12.892578 L11,12.892578 L11,9 L12,9 C12.552,9 13,8.552 13,8 C13,7.448 12.552,7 12,7 L12,7 Z' />
                    </g>
                  </svg>
                ),
              },
              {
                label: 'ดูทั้งหมด',
              },
            ]

            return (
              <div className='MediaIndex__filter'>
                {filters.map((x, i) => (
                  <a
                    key={i}
                    className={className('MediaIndex__filter__a', {
                      'MediaIndex__filter__a--active':
                        get(qs, 'type') === get(x, 'key'),
                    })}
                    onClick={() => href(get(x, 'key'))}
                  >
                    {get(x, 'icon')}

                    <span className='MediaIndex__filter__a__span'>
                      {get(x, 'label')}
                    </span>
                  </a>
                ))}
              </div>
            )
          })()}

          <SearchForm page='Medias' />
        </div>

        {get(media, 'length') > 0 ? (
          <div
            className='Table'
            style={{
              gridTemplateColumns:
                'max-content 320px repeat(3, max-content) 1fr max-content',
            }}
          >
            <div className='Table__row'>
              {[
                {
                  label: '',
                },
                {
                  label: 'ชื่อไฟล์',
                },
                {
                  label: 'ประเภทไฟล์',
                },
                {
                  label: 'ผู้ส่ง',
                },
                {
                  label: 'เวลา',
                },
                {
                  label: 'โน๊ต',
                },
                {
                  label: '',
                },
              ].map((x, i) => (
                <div className='Table__col Table__col--header' key={i}>
                  {get(x, 'label')}
                </div>
              ))}
            </div>

            {media.map((x, i) => {
              const filename = get(x, 'filename').split('.')
              const extension = filename[filename.length - 1]
              const onClick = () => {
                modalDataSet({
                  isActive: true,
                  data: x,
                })
              }
              return (
                <div className='Table__row' key={i} data-x={x}>
                  <div className='Table__col'>
                    <div className='Table__col__preview'>
                      {get(x, 'storage_type') === 'image' ? (
                        <img src={get(x, 'data.url')} />
                      ) : (
                        <FileIcon
                          extension={`${extension}`}
                          {...defaultStyles[`${extension}`]}
                        />
                      )}
                    </div>
                  </div>
                  <div className='Table__col'>
                    {start_and_end(get(x, 'filename'))}
                  </div>
                  <div className='Table__col'>{get(x, 'storage_type')}</div>
                  <div className='Table__col'>{get(x, 'sender')}</div>
                  <div className='Table__col'>
                    {moment(get(x, 'updated_at')).format('YYYY-MM-DD HH:mm')}
                  </div>
                  <div className='Table__col__note'>
                    <a className='Table__col__note__edit' onClick={onClick} />
                    {get(x, 'remark') ? (
                      <div className='Table__col__note__text'>
                        <span
                          className='tooltiptext'
                          data-tooltip={get(x, 'remark')}
                        />
                        {get(x, 'remark')}
                      </div>
                    ) : (
                      <a className='Table__col__note__span' onClick={onClick}>
                        เพิ่มโน๊ต
                      </a>
                    )}
                  </div>

                  <div className='Table__col'>
                    <a
                      className='Table__col__a'
                      href={get(x, 'data.url')}
                      target='_blank'
                      rel='nofollow noopener noreferrer'
                    >
                      ดาวน์โหลดไฟล์
                    </a>
                  </div>
                </div>
              )
            })}
            <Pagination
              total_files={total}
              current_page={current_page}
              total_pages={total_pages}
            />
          </div>
        ) : (
          <div className='Placeholder'>
            <h6 className='Placeholder__h6'>ไม่พบไฟล์</h6>

            {false && (
              <React.Fragment>
                <p className='Placeholder__p'>
                  กรุณาทำการเชื่อมต่อห้องสนทนากับบอทก่อนใช้งาน
                </p>
              </React.Fragment>
            )}
          </div>
        )}
      </div>
      <ModalEditRemark
        modalData={modalData}
        modalDataSet={modalDataSet}
        source='media'
      />
    </>
  )
}

MediaIndex.propTypes = {}

export default MediaIndex
