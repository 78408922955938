import React from 'react'
import PropTypes from 'prop-types'
import {get, first} from 'lodash'
import className from 'classnames'
// import {Link} from 'react-router-dom'

const ImageLoader = ({src, klass, type}) => {
  const $el = React.useRef()
  const [isActive, isActiveSet] = React.useState(false)
  const [isWithinView, isWithinViewSet] = React.useState(false)

  const observer = React.useRef(
    new IntersectionObserver(
      (elements) => {
        const intersectionRatio = get(first(elements), 'intersectionRatio')

        if (intersectionRatio > 0) {
          isWithinViewSet(true)
        }
      },
      {
        rootMargin: '200px 0px',
        threshold: Array.from(Array(5)).map((x, i) => i / 4), // Make 5 thresholds just to make sure one of them gets fired.
      }
    )
  )

  React.useEffect(() => {
    if (isWithinView) {
      observer.current.disconnect()

      if (!type) {
        // For images, check for their existence first.
        const _img = new Image()
        _img.src = src

        _img.onload = () => {
          isActiveSet(true)
        }

        _img.onerror = () => {}
      } else {
        isActiveSet(true)
      }
    }
  }, [isWithinView])

  React.useEffect(() => {
    // Shouldn't start loading right away because loaded contents
    // have heights, which would disrupt the scroll position.
    window.setTimeout(() => {
      observer.current.observe($el.current)
    }, 1500)

    return () => {
      observer.current.disconnect()
    }
  }, [])

  if (isActive) {
    switch (type) {
      case 'location':
        return <iframe className={klass} src={src} />
      case 'audio':
        return (
          <audio controls className={klass} src={src}>
            Your browser does not support the audio element.
          </audio>
        )
      case 'video':
        return <video controls className={klass} src={src} />
      default:
        return <img className={klass} src={src} />
    }
  }

  return <div className='ImageLoader' ref={$el}></div>
}

ImageLoader.propTypes = {}

export default ImageLoader
