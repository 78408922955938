import React, {useState, useEffect} from 'react'
import {
  QrCodeScan,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Box,
  useToast,
} from '@datawowio/payment-ui'

import {usePurchasesContext} from './store'
import {getQrSource, getQrPaymentStatus} from './services'

function QrModal() {
  const {isOpenQrModal, onCloseQrModal} = usePurchasesContext()

  return (
    <Modal
      isOpen={isOpenQrModal}
      onClose={onCloseQrModal}
      blockScrollOnMount={false}
      closeOnOverlayClick={false}
      isCentered
    >
      <ModalOverlay />
      <ModalContent fontFamily='body' maxWidth='900px'>
        <ModalHeader>ชำระเงินผ่าน QR Code</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Content onCloseQrModal={onCloseQrModal} />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

const PAYMENT_STATUS = {
  PENDING: 'pending',
  SUCCESS: 'success',
  FAILED: 'failed',
}

const Content = ({onCloseQrModal}) => {
  const toast = useToast()
  const {token, hostUrl} = usePurchasesContext()

  const [paymentStatus, setPaymentStatus] = useState()
  const [qrSource, setQrSource] = useState({
    amount: '0',
    qr_image: '',
    ref_no: '',
  })

  const getQrSourceService = async () => {
    try {
      const data = await getQrSource({token})

      setQrSource(data)
    } catch (error) {}
  }

  const getQrPaymentStatusService = async () => {
    try {
      const data = await getQrPaymentStatus({refNo: qrSource.ref_no})
      setPaymentStatus(data.status)
    } catch (error) {}
  }

  useEffect(() => {
    getQrSourceService()
  }, [])

  useEffect(() => {
    if (paymentStatus === PAYMENT_STATUS.SUCCESS) {
      onCloseQrModal()
      window.location.href = `${hostUrl}/payments/success?token=${token}`
    }

    if (paymentStatus === PAYMENT_STATUS.FAILED) {
      onCloseQrModal()
      toast({
        title: 'จ่ายเงินไม่สำเร็จ',
        status: 'error',
        position: 'top-right',
        isClosable: true,
      })
    }
  }, [paymentStatus])

  return (
    <Box>
      <QrCodeScan
        amount={qrSource.amount}
        qrImageUrl={qrSource.qr_image}
        refNo={qrSource.ref_no}
        endTimeInSecond={180}
        fireOnInterval={() => {
          getQrPaymentStatusService()
        }}
        onTimeEnd={() => {
          onCloseQrModal()
          toast({
            title: 'QR Code หมดอายุ',
            description: 'กรุณากดดำเนินการชำระเงินใหม่อีกครั้ง',
            status: 'warning',
            position: 'top',
            isClosable: true,
            duration: 10000,
          })
        }}
      />
    </Box>
  )
}

export default QrModal
