import React, {useCallback} from 'react'
import {get} from 'lodash'
import {useDropzone} from 'react-dropzone'

import {axios} from '@lib/helpers'

import ModalEditRoomName from '@components/molecules/ModalEditRoomName'
import ModalRoomSharing from './ModalRoomSharing'

const SourcesShowHeader = ({source}, ref) => {
  const [modalData, modalDataSet] = React.useState({
    isActive: false,
  })
  const [roomSharingModal, setRoomSharingModal] = React.useState({
    isActive: false,
  })

  const handleUploadPrivateKey = useCallback((files) => {
    const file = files[0]
    const formData = new FormData()

    formData.append('private_key', file)

    axios
      .post(
        `/sources/${get(source, 'source_type_name')}/${get(
          source,
          'source_id'
        )}/upload`,
        formData
      )
      .then((res) => {
        window.location.reload()
      })
      .catch((err) => console.error(err))
  }, [])

  const {open, getRootProps, getInputProps} = useDropzone({
    onDrop: handleUploadPrivateKey,
    accept: ['.pem'],
    multiple: false,
    noClick: true,
    noKeyboard: true,
  })

  const handleDownloadPrivateKey = async () => {
    axios
      .delete(
        `/sources/${get(source, 'source_type_name')}/${get(
          source,
          'source_id'
        )}/delete_key`
      )
      .then((res) => {
        window.location.reload()
      })
      .catch((err) => console.error(err))
  }

  return (
    <React.Fragment>
      <div className='Header'>
        <div className='Header__breadcrumb'>
          <a className='Header__breadcrumb__a' href='/sources'>
            ห้องสนทนา
          </a>

          <h2 className='Header__h2'>
            <span className='Header__breadcrumb__current'>
              <div className='Header__breadcrumb__current__edit-room'>
                {get(source, 'name') || 'ไม่มีชื่อห้อง'}

                {get(source, 'user.id') === gon.current_user_id && (
                  <a
                    className='Table__col__chatroom-name__edit'
                    onClick={() => {
                      modalDataSet({
                        isActive: true,
                        data: source,
                      })
                    }}
                  />
                )}
              </div>
            </span>
          </h2>
        </div>

        <div className='Header__button-wrapper'>
          {!get(source, 'has_main_key', false) && (
            <React.Fragment>
              <div {...getRootProps()}>
                {get(source, 'has_private_key', true) ? (
                  <a
                    className='Button Button--red Button--red--alt Button--download'
                    onClick={handleDownloadPrivateKey}
                  >
                    ลบคีย์
                  </a>
                ) : (
                  <React.Fragment>
                    <input {...getInputProps()} />
                    <a
                      className='Button Button--orange Button--orange--alt Button--upload'
                      onClick={open}
                    >
                      อัปโหลดคีย์
                    </a>
                  </React.Fragment>
                )}
              </div>
            </React.Fragment>
          )}

          {get(source, 'user.id') === gon.current_user_id && (
            <a
              className='Button Button--orange Button--with-share'
              onClick={() => {
                setRoomSharingModal(() => ({isActive: true, data: source}))
              }}
            >
              แชร์ห้องสนทนา
            </a>
          )}
        </div>
      </div>

      <ModalEditRoomName modalData={modalData} modalDataSet={modalDataSet} />
      <ModalRoomSharing
        modalData={roomSharingModal}
        modalDataSet={setRoomSharingModal}
      />
    </React.Fragment>
  )
}

export default SourcesShowHeader
