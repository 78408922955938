import React, {useMemo} from 'react'
import * as queryString from 'query-string'

import DateRangeInput from './DateRangeInput'

const SearchForm = ({page}) => {
  const parsed = useMemo(() => queryString.parse(location.search), [
    JSON.stringify(location.search),
  ])
  return (
    <form
      className={`${page}__search`}
      onSubmit={(e) => {
        e.preventDefault()
        const form = e.currentTarget
        const query = form.elements.query.value
        const date = form.elements.date.value

        const [start_date, end_date] = date.split(' - ')

        location.search = queryString.stringify({
          ...parsed,
          query,
          start_date,
          end_date,
          page: 1,
        })
      }}
    >
      <DateRangeInput parsed={parsed} />
      <input
        type='text'
        className='Input Input--search'
        id='query'
        placeholder='ค้นหา'
        defaultValue={parsed?.query}
      />
      <button className='Button'>ค้นหา</button>

      <a
        className='Button Button--trans'
        onClick={() => {
          delete parsed.query
          delete parsed.start_date
          delete parsed.end_date
          parsed.page = 1
          location.search = queryString.stringify({
            ...parsed,
          })
        }}
      >
        ล้าง
      </a>
    </form>
  )
}

export default SearchForm
