import {get, replace, isString, first, isEmpty} from 'lodash'
import {combineReducers} from 'redux'
import {createStore, applyMiddleware, compose} from 'redux'
import thunk from 'redux-thunk'
import {multiClientMiddleware} from 'redux-axios-middleware'
import * as queryString from 'query-string'

import {_axios} from '@lib/helpers'

// Actions

export const loadMessages = ({source, messages}) => {
  return {
    type: 'MESSAGES_LOAD',
    payload: {
      client: 'default',
      request: {
        url: `/sources/${get(source, 'source_type_name')}/${get(
          source,
          'source_id'
        )}/messages?${queryString.stringify({
          prev: get(messages, 'data.meta.prev'),
        })}`,
      },
    },
  }
}

// Reducers

const defaultState = {
  isLoading: false,
  isError: false,
  data: {},
}

export const loadingState = (state) => ({
  ...state,
  isLoading: true,
  isError: false,
})

export const loadedState = (state, data) => ({
  ...state,
  isLoading: false,
  isError: false,
})

export const failedState = (state, data) => ({
  ...state,
  isLoading: false,
  isError: true,
})

const reducers = combineReducers({
  isMessagesDisplayed: (state = false, action) => {
    switch (action.type) {
      case 'MESSAGES_DISPLAY': {
        return true
      }
      default:
        return state
    }
  },

  isSearching: (state = false, action) => {
    switch (action.type) {
      case 'MESSAGES_SEARCH_RESULTS_LOAD': {
        return true
      }
      case 'MESSAGES_SEARCH_CLEAR': {
        return false
      }
      default:
        return state
    }
  },

  messages: (state = defaultState, action) => {
    switch (action.type) {
      case 'MESSAGES_LOAD':
        return loadingState(state)
      case 'MESSAGES_LOAD_SUCCESS': {
        let data = get(action, 'payload.data', {})

        return loadedState({
          ...state,
          data: {
            ...data,
            messages: [
              ...get(data, 'messages'),
              ...(get(state, 'data.messages') || []),
            ],
          },
        })
      }
      default:
        return state
    }
  },

  searchResults: (state = defaultState, action) => {
    switch (action.type) {
      case 'MESSAGES_SEARCH_RESULTS_LOAD':
        return loadingState(state)
      case 'MESSAGES_SEARCH_RESULTS_LOAD_SUCCESS': {
        let data = get(action, 'payload.data', {})

        return loadedState({
          ...state,
          data,
        })
      }
      case 'MESSAGES_SEARCH_CLEAR':
        return defaultState
      default:
        return state
    }
  },

  searchMessages: (state = defaultState, action) => {
    switch (action.type) {
      case 'MESSAGES_SEARCH_LOAD':
        return loadingState(defaultState)
      case 'MESSAGES_SEARCH_RESULTS_LOAD':
        return defaultState
      case 'MESSAGES_SEARCH_LOAD_SUCCESS': {
        let data = get(action, 'payload.data', {})

        return loadedState({
          ...state,
          data,
        })
      }
      case 'MESSAGES_SEARCH_CLEAR':
        return defaultState
      default:
        return state
    }
  },
})

// Store

export const store = createStore(
  reducers,
  compose(
    applyMiddleware(
      thunk,
      multiClientMiddleware({
        default: {
          client: _axios,
        },
      })
    ),
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : (f) => f
  )
)
