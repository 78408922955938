import React from 'react'
import {get, capitalize, split} from 'lodash'
import className from 'classnames'
import moment from 'moment'

import {axios} from '@lib/helpers'

import TabsPackages from '@components/molecules/TabsPackages'
import Plans from '@components/molecules/Plans'

const PackagesIndex = ({
  data,
  packageDetails,
  quota_usage,
  small_package_token: smallPackageToken,
  medium_package_token: mediumPackageToken,
  hostUrl,
  recurring_status: recurringStatus,
  partner_packages: partnerPackages,
  partner_code: partnerCode,
}) => {
  const packageName = get(data, 'name')
  let capitalizedPackageName
  switch (packageName) {
    case 'small_medium':
      capitalizedPackageName = 'S/M Package'
      break
    case 'small_plus':
      capitalizedPackageName = 'S+ Package'
      break
    default:
      capitalizedPackageName = `${capitalize(packageName)} Package`
  }
  const hiddenPricePackages = ['small_medium', 'small_plus']
  const price = hiddenPricePackages.includes(packageName)
    ? ''
    : get(packageDetails, 'price')

  return (
    <div className='PackagesIndex'>
      <div className='Header'>
        <h2 className='Header__h2'>
          <span className='Header__breadcrumb__current'>แพ็กเกจของคุณ</span>
        </h2>
      </div>

      <TabsPackages />

      <div className='PackagesIndex__current-plan'>
        <div className='PackagesIndex__current-plan__wrapper'>
          <div className='PackagesIndex__current-plan__left'>
            <p className='PackagesIndex__current-plan__left__p'>
              ขณะนี้คุณกำลังใช้
            </p>
            <h3 className='PackagesIndex__current-plan__left__h3'>
              {capitalizedPackageName}
            </h3>
            <span className='PackagesIndex__current-plan__left__span'>
              {price},
            </span>
            <small className='PackagesIndex__current-plan__left__small'>
              {get(data, 'expiry') &&
                `ใช้ได้ถึงวันที่ ${moment(get(data, 'expiry')).format(
                  'D MMMM YYYY'
                )}`}
            </small>
            {false && ['active', 'error'].includes(recurringStatus) && (
              <a
                className='Button Button--red'
                onClick={() =>
                  axios.post('/packages/cancel').finally(() => {
                    window.location.reload()
                  })
                }
              >
                ยกเลิกแพ็กเกจ
              </a>
            )}
            {false && recurringStatus === 'cancelling' && (
              <span className='Button Button--gray'>กำลังยกเลิกแพ็กเกจ</span>
            )}
            {false && recurringStatus === 'error' && (
              // TODO: fix color
              <small style={{color: 'red'}}>* ยกเลิกแพ็กเกจไม่สำเร็จ</small>
            )}
          </div>

          <dl className='PackagesIndex__current-plan__dl'>
            {[
              {
                dd: get(quota_usage, 'rooms'),
                dt: 'ห้องสนทนา',
                small: get(packageDetails, 'rooms'),
              },
              {
                dd: (
                  <span
                    className={className(
                      'PackagesIndex__current-plan__dl__item__dd__messages',
                      {
                        'PackagesIndex__current-plan__dl__item__dd__messages--red':
                          get(quota_usage, 'messages') >=
                          get(packageDetails, 'messages'),
                      }
                    )}
                  >
                    {get(quota_usage, 'messages', 0)}
                  </span>
                ),
                dt: (
                  <span className='PackagesIndex__current-plan__dl__item__dt__span'>
                    ข้อความ
                    {get(quota_usage, 'messages') >=
                      get(packageDetails, 'messages') && (
                      <a className='PackagesIndex__current-plan__dl__item__dt__exceed'>
                        <div className='PackagesIndex__current-plan__dl__item__dt__exceed__panel'>
                          <span className='PackagesIndex__current-plan__dl__item__dt__exceed__panel__span'>
                            ข้อความของคุณเกินโควต้าที่กำหนด <br />
                            แนะนำควรอัปเกรดแพลน
                          </span>
                        </div>
                      </a>
                    )}
                  </span>
                ),
                small: `${get(packageDetails, 'messages', 0)}`,
              },
              {
                dd: (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: split(get(quota_usage, 'storages'), ' ')
                        .map((x, i) => (i === 1 ? `<small>${x}</small>` : x))
                        .join(' '),
                    }}
                  />
                ),
                dt: 'พื้นที่จัดเก็บ',
                small: get(packageDetails, 'storages'),
              },
              {
                dd: get(packageDetails, 'encryption') ? '✓' : '✗',
                dt: 'รองรับการเข้ารหัส',
                small: '&nbsp;',
              },
            ].map((x, i) => (
              <div className='PackagesIndex__current-plan__dl__item' key={i}>
                <div className='PackagesIndex__current-plan__dl__item__wrapper'>
                  <dd className='PackagesIndex__current-plan__dl__item__dd'>
                    {get(x, 'dd')}
                  </dd>

                  <dt className='PackagesIndex__current-plan__dl__item__dt'>
                    {get(x, 'dt')}
                  </dt>

                  <small
                    className='PackagesIndex__current-plan__dl__item__small'
                    dangerouslySetInnerHTML={{__html: get(x, 'small')}}
                  />
                </div>
              </div>
            ))}
          </dl>
        </div>
      </div>

      <div className='PackagesIndex__plans'>
        <h3 className='PackagesIndex__plans__h3'>เลือกแพลนที่คุณต้องการ</h3>

        <Plans
          hostUrl={hostUrl}
          smallPackageToken={smallPackageToken}
          mediumPackageToken={mediumPackageToken}
          packageDetails={data}
          partnerPackages={partnerPackages}
          partnerCode={partnerCode}
        />
      </div>
    </div>
  )
}

PackagesIndex.propTypes = {}

export default PackagesIndex
