import React, {useState, useEffect} from 'react'
import {get} from 'lodash'
import {useDropzone} from 'react-dropzone'

import {axios} from '@lib/helpers'

import Tabs from '@components/molecules/Tabs'
import SourcesShowHeader from '@components/molecules/SourcesShowHeader'

const ImportIndex = (props) => {
  const {source, showDownload: defaultShowDownload} = props

  const [isExporting, setIsExporting] = useState(false)
  const [showDownload, setShowDownload] = useState(defaultShowDownload)
  const [busy, setBusy] = useState(false)

  useEffect(() => {
    if (isExporting) {
      const poll = async () => {
        if (!busy) {
          setBusy(true)
          const response = await axios.get(
            `/sources/${get(source, 'source_type_name')}/${get(
              source,
              'source_id'
            )}/export/check`
          )
          if (response.data) {
            setIsExporting(false)
            setShowDownload(true)
          }
          setBusy(false)
        }
      }
      const interval = setInterval(poll, 5000)
      return () => clearInterval(interval)
    }

    return () => null
  }, [isExporting, busy, setBusy])

  const onDrop = React.useCallback((acceptedFiles) => {
    const file = acceptedFiles[0]
    const formData = new FormData()

    formData.append('file', file)

    axios
      .post(
        `/sources/${get(source, 'source_type_name')}/${get(
          source,
          'source_id'
        )}/import`,
        formData
      )
      .then(
        (res) =>
          (window.location = `${window.location.origin}/sources/${get(
            source,
            'source_type_name'
          )}/${get(source, 'source_id')}`)
      )
      .catch((err) => console.error(err))
  }, [])

  const {getRootProps, getInputProps} = useDropzone({
    onDrop,
    accept: ['.txt'],
    multiple: false,
  })

  const exportChatHistory = () => {
    setIsExporting(true)
    axios.post(
      `/sources/${get(source, 'source_type_name')}/${get(
        source,
        'source_id'
      )}/export`,
      {}
    )
  }

  const downloadChatHistory = () => {
    window.location.href = `/sources/${get(source, 'source_type_name')}/${get(
      source,
      'source_id'
    )}/export`
  }

  const downloadAndExportButton = () => {
    if (isExporting) {
      return (
        <div className='ImportIndex__export__exporting'>
          <div className='ImportIndex__export__exporting__refresh' />
          กำลังดำเนินการ ลิงก์ดาวน์โหลดจะปรากฏเมื่อเสร็จสิ้น
        </div>
      )
    } else {
      return (
        <>
          {showDownload && (
            <button
              className='Button Button--green ImportIndex__export__download-button'
              onClick={downloadChatHistory}
            >
              ดาวน์โหลด .txt
            </button>
          )}
          <button
            className='Button ImportIndex__export__export-button'
            onClick={exportChatHistory}
          >
            เอ็กซ์พอร์ต .txt
          </button>
        </>
      )
    }
  }

  return (
    <div className='ImportIndex'>
      <SourcesShowHeader source={source} />
      <Tabs source={source} />

      <div className='ImportIndex__import'>
        <div className='ImportIndex__import__title'>
          <div className='ImportIndex__import__svg' />
          <p className='ImportIndex__import__p'>นำเข้าประวัติการแชท</p>
          <p className='ImportIndex__import__p--light'>
            คุณสามารถนำเข้า/อัปโหลด ประวัติการแชทที่บันทึกจาก PC หรือ โทรศัพท์
            ก่อนหน้าที่จะใช้ JotT เพื่อรวมข้อความทั้งหมดไว้ที่นี่{' '}
          </p>
        </div>

        <div className='ImportIndex__import__dropzone' {...getRootProps()}>
          <input {...getInputProps()} />
          <div className='ImportIndex__import__dropzone__svg' />
          <div className='ImportIndex__import__dropzone__p'>
            ลากหรืออัปโหลดไฟล์สนทนาที่ได้จาก LINE เท่านั้น
          </div>
          <div className='ImportIndex__import__dropzone__p--light'>
            รูปแบบไฟล์ที่รองรับ .txt
          </div>
          <button className='Button'>อัปโหลดหรือลากไฟล์</button>
        </div>
      </div>
      <div className='ImportIndex__export'>
        <div className='ImportIndex__export__title'>
          <div className='ImportIndex__export__svg' />
          <p>สำรองประวัติการแชท</p>
          <p className='ImportIndex__export__p--light'>
            ดาวน์โหลดประวัติการแชททั้งหมดที่นี่ ไฟล์จะอยู่ในรูปแบบ .txt
          </p>
          {downloadAndExportButton()}
        </div>
      </div>
    </div>
  )
}

export default ImportIndex
