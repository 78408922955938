import React from 'react'
import ReactDOM from 'react-dom'
import {get} from 'lodash'
import className from 'classnames'

const Modal = ({modalData, modalDataSet, children}) => {
  return ReactDOM.createPortal(
    <div
      className={className('Modal', {
        'Modal--active': get(modalData, 'isActive'),
      })}
    >
      <a
        className='Modal__overlay'
        onClick={() => {
          modalDataSet({
            ...modalData,
            isActive: false,
          })
        }}
      />

      <div className='Modal__wrapper'>
        <a
          className='Modal__x'
          onClick={() => {
            modalDataSet({
              ...modalData,
              isActive: false,
            })
          }}
        />

        {children}
      </div>
    </div>,
    document.querySelector('#modals')
  )
}

Modal.propTypes = {}

export default Modal
