import React from 'react'
import {
  ConfirmPaymentForm,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useToast,
} from '@datawowio/payment-ui'
import {getErrorMsg} from '@lib/helpers'

import {usePurchasesContext} from './store'
import {saveConfirmTransfer} from './services'

function TransferConfirmModal() {
  const {
    isOpenTransferModal,
    onCloseTransferModal,
    token,
    hostUrl,
  } = usePurchasesContext()
  const toast = useToast()
  const [loading, setLoading] = React.useState(false)

  const onSubmit = async (values) => {
    setLoading(true)

    try {
      const formData = new FormData()
      formData.append('token', token)
      formData.append('amount', values.amount)
      formData.append('slip', values.slip)
      formData.append('phone_number', values.phone)
      formData.append('remark', values.remark)

      await saveConfirmTransfer(formData)

      setLoading(false)

      window.location.href = `${hostUrl}/payments/success?token=${token}`
    } catch (error) {
      setLoading(false)
      toast({
        title: getErrorMsg(error),
        status: 'error',
        position: 'top-right',
        isClosable: true,
        duration: null,
      })
    }
  }

  return (
    <Modal
      isOpen={isOpenTransferModal}
      onClose={onCloseTransferModal}
      blockScrollOnMount={false}
      closeOnOverlayClick={false}
      isCentered
    >
      <ModalOverlay />
      <ModalContent fontFamily='body' maxWidth='800px'>
        <ModalHeader>ยืนยันการโอนเงิน</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <ConfirmPaymentForm onSubmit={onSubmit} isLoading={loading} />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default TransferConfirmModal
