import React, {useState, useEffect, useRef, useMemo} from 'react'
import Calendar from 'react-calendar'
import moment from 'moment'
import 'react-calendar/dist/Calendar.css'

const DateRangeInput = ({parsed}) => {
  const wrapperRef = useRef(null)

  const initailStartDate = useMemo(
    () => parsed?.start_date && new Date(parsed?.start_date),
    [parsed?.start_date]
  )

  const initailEndDate = useMemo(
    () => parsed?.end_date && new Date(parsed?.end_date),
    [parsed?.end_date]
  )

  const [value, setValue] = useState([initailStartDate, initailEndDate])
  const [isOpen, setIsOpen] = useState(false)
  const [dateRange, setDateRange] = useState(value)
  const [activeDate, setActiveDate] = useState(initailStartDate)

  const selectDate = ([firstDate, lastDate]) => {
    setDateRange([firstDate, lastDate])
    setActiveDate(firstDate)
  }

  const setting = (date, config) =>
    new Date(date).toLocaleDateString('th-TH', config)

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [wrapperRef])

  return (
    <div ref={wrapperRef}>
      <input
        type='text'
        className='Input Input--calendar'
        placeholder='YYYY-MM-DD - YYYY-MM-DD'
        readOnly
        value={value
          .filter((date) => !!date)
          .map((date) => moment(date).format('YYYY-MM-DD'))
          .join(' - ')}
        onClick={() => setIsOpen(!isOpen)}
        id='date'
      />

      {isOpen && (
        <div className='Calendar'>
          <div className='Calendar__menu'>
            <a
              className='Button Button--ghost'
              onClick={() => {
                const date = moment().toDate()
                selectDate([date, date])
              }}
            >
              วันนี้
            </a>
            <a
              className='Button Button--ghost'
              onClick={() => {
                const date = moment().subtract(1, 'day').toDate()
                selectDate([date, date])
              }}
            >
              เมื่อวาน
            </a>
            <a
              className='Button Button--ghost'
              onClick={() => {
                const curr = moment()
                const firstDay = curr.startOf('week').toDate()
                const lastDay = curr.endOf('week').toDate()
                selectDate([firstDay, lastDay])
              }}
            >
              อาทิตย์นี้
            </a>
            <a
              className='Button Button--ghost'
              onClick={() => {
                const curr = moment()
                const firstDay = curr.startOf('month').toDate()
                const lastDay = curr.endOf('month').toDate()
                selectDate([firstDay, lastDay])
              }}
            >
              เดือนนี้
            </a>
            <a
              className='Button Button--ghost'
              onClick={() => {
                const curr = moment()
                const firstDay = curr.startOf('year').toDate()
                const lastDay = curr.endOf('year').toDate()
                selectDate([firstDay, lastDay])
              }}
            >
              ปีนี้
            </a>
          </div>
          <div>
            <Calendar
              selectRange
              allowPartialRange
              showDoubleView
              returnValue='range'
              calendarType='US'
              formatMonthYear={(_locale, date) =>
                setting(date, {
                  month: 'long',
                  year: 'numeric',
                })
              }
              formatYear={(_locale, date) =>
                setting(date, {
                  year: 'numeric',
                })
              }
              formatMonth={(_locale, date) =>
                setting(date, {
                  month: 'long',
                })
              }
              formatShortWeekday={(_locale, date) =>
                new Date(date).toLocaleDateString('th-TH', {
                  weekday: 'short',
                })
              }
              value={dateRange}
              onActiveStartDateChange={({activeStartDate, view, action}) => {
                if (view !== 'month' || action !== 'onChange') {
                  setActiveDate(activeStartDate)
                }
              }}
              activeStartDate={activeDate}
              onChange={(value) => {
                selectDate(value)
              }}
            />
            <div className='Calendar--last_row'>
              <a
                className='Button Button--ghost'
                onClick={() => selectDate([])}
              >
                ล้าง
              </a>
              <div className='Calendar--last_col'>
                <a
                  className='Button Button--ghost'
                  onClick={() => {
                    const value = dateRange.filter((date) => !!date)
                    setValue(value?.length === 2 ? value : [...value, ...value])
                    setIsOpen(false)
                  }}
                >
                  บันทึก
                </a>
                <a
                  className='Button Button--ghost'
                  onClick={() => {
                    selectDate(value)
                    setIsOpen(false)
                  }}
                >
                  ปิด
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default DateRangeInput
