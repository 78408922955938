import React from 'react'
import {
  Box,
  Flex,
  Text,
  Grid,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  SelectCreditCard,
  CardItem,
  AddCreditCardForm,
  Spinner,
  Summary,
  useToast,
  PaymentMethodContainer,
} from '@datawowio/payment-ui'
import {isEmpty, capitalize} from 'lodash'
import {IoChevronForward} from 'react-icons/io5'
import {FiCheck, FiX} from 'react-icons/fi'

import ExtertedField from '@components/molecules/ExtertedField'

import {withPurchasesPage, usePurchasesContext} from './store'
import {usePurchasesServices} from './services'

function Purchases({omise_public_key}) {
  const {
    creditCards,
    packageData,
    paymentMethod,
    hostUrl,
    partner,
    setPartner,
    email,
    setEmail,
  } = usePurchasesContext()

  const {addNewCard, onSubmitPayment, onCheckPartner} = usePurchasesServices()

  const [errors, setErrors] = React.useState({})
  const toast = useToast()

  React.useEffect(() => {
    if (errors.selectedPayment) {
      toast({
        title: errors.selectedPayment,
        status: 'error',
        position: 'top',
        isClosable: true,
      })
    }

    Omise.setPublicKey(omise_public_key)
  }, [errors])

  return (
    <Box backgroundColor='#fff' p='6' borderRadius='8px'>
      <Flex mb='12' alignItems='center'>
        <Text fontSize='2xl'>สรุปรายการสั่งซื้อ</Text>
        <Icon as={IoChevronForward} mr='2' ml='2' size='30px' />
        <Text fontSize='2xl' fontWeight='700'>
          เลือกวิธีการชำระเงิน
        </Text>
      </Flex>
      <Grid gap='6' gridTemplateColumns='1fr 440px' alignItems='start'>
        <Grid gap='6' height='100%' gridTemplateRows='1fr auto'>
          <PaymentMethodContainer>
            <Box>
              <SelectCreditCard
                acceptedCards={['visa', 'mastercard']}
                renderCreditForm={({onClose}) => {
                  return (
                    <AddCreditCardForm
                      onSubmit={(values) => {
                        addNewCard(values, {
                          onSuccess: () => onClose(),
                        })
                      }}
                    />
                  )
                }}
              >
                <CreditCardList
                  cards={creditCards.filter(
                    (card) => card.status !== 'deprecated'
                  )}
                />
              </SelectCreditCard>
            </Box>
          </PaymentMethodContainer>
          <ExtertedField
            title='พาร์ทเนอร์'
            description='ใส่โค้ดของพาร์ทเนอร์ของคุณ เพื่อเชื่อมต่อได้ที่นี่'
          >
            <InputGroup>
              <Input
                placeholder={'โค้ด'}
                value={partner.code ?? ''}
                onChange={(e) =>
                  setPartner({...partner, valid: null, code: e.target.value})
                }
                onBlur={partner.code && onCheckPartner}
              />
              <InputRightElement>
                {partner.checking ? (
                  <Spinner />
                ) : partner.valid ? (
                  <FiCheck color='#00b900' />
                ) : (
                  partner.valid === false && <FiX color='#f97272' />
                )}
              </InputRightElement>
            </InputGroup>
          </ExtertedField>
          <ExtertedField
            title={
              <>
                อีเมล
                <Text
                  as='span'
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '20px',
                    mr: 4,
                    color: 'red',
                  }}
                >
                  *
                </Text>
              </>
            }
            description='สำหรับส่งรับใบเสร็จ จาก JotT'
          >
            <Input
              placeholder={'อีเมล'}
              value={email ?? ''}
              onChange={(e) => setEmail(e.target.value)}
              type='email'
              isRequired
            />

            <Text
              sx={{
                mt: 4,
                fontSize: '14px',
                color: 'red',
              }}
            >
              {errors.email}
            </Text>
          </ExtertedField>
        </Grid>

        <Summary
          net={packageData.priceExcludeVat}
          total={packageData.total}
          vat={packageData.vat}
          vatPercent={packageData.vatPercent}
          preVatTotal={packageData.priceExcludeVat}
          name={capitalize(packageData.name)}
          onErrors={(err) => {
            setErrors(err)
          }}
          extraValues={{
            selectedPayment: paymentMethod.type,
            email,
          }}
          validate={(values) => {
            const errors = {}

            if (!values.selectedPayment) {
              errors.selectedPayment = 'จำเป็นต้องเลือกรูปแบบการจ่ายเงิน'
            }

            if (!values.email) {
              errors.email = 'จำเป็นต้องกรอกอีเมล'
            } else if (
              !new RegExp('[a-z0-9]+@[a-z]+.[a-z]{2,3}').test(values.email)
            ) {
              errors.email = 'อีเมลไม่ผ่านการตรวจสอบ'
            }

            return errors
          }}
          extraCondition={paymentMethod.type !== ''}
          linkTermOfUse={`${hostUrl}/terms`}
          linkPrivacy={`${hostUrl}/privacy`}
          address='บริษัท เอ็มพลัส อินเตอร์เนชั่นแนล จำกัด (สำนักงานใหญ่) 496-502 อาคารอัมรินทร์พลาซ่า ห้องเลขที่ 27 ชั้น 10 ถนนเพลินจิต แขวงลุมพินี เขตปทุมวัน กรุงเทพมหานคร 10330 เลขประจำตัวผู้เสียภาษี 0105545000126'
          onSubmit={onSubmitPayment}
          withHoldingTaxPercent={packageData.companyVatPercent}
          withHoldingTax={packageData.companyVat}
        />
      </Grid>
    </Box>
  )
}

const CreditCardList = ({cards}) => {
  const {paymentMethod, setPaymentMethod} = usePurchasesContext()
  const {deleteCard, setPrimaryCard} = usePurchasesServices()

  if (isEmpty(cards)) {
    return (
      <Box p='4' textAlign='center' color='gray.400'>
        ไม่มีบัตรเครดิต
      </Box>
    )
  }

  return (
    <Box>
      {cards.map((card) => {
        const {id} = card

        return (
          <CardItem
            key={id}
            id={id}
            isSelected={paymentMethod.payload === id}
            primary={card.primary}
            cardType={card.brand || ''}
            shownNumber={card.number}
            onClick={() => {
              setPaymentMethod({
                type: 'credit',
                payload: id,
              })
            }}
            onDelete={() => {
              deleteCard(id)
            }}
            onEdit={() => {
              setPrimaryCard(id)
            }}
          />
        )
      })}
    </Box>
  )
}

export default withPurchasesPage(Purchases)
