import React from 'react'
import Plans from '@components/molecules/Plans'

import heroImg from '@images/landing/Home__hero__img.svg'
import startImg from '@images/landing/Home__start.svg'
import start0 from '@images/landing/Home__start__dl__item__dt__img--0.svg'
import start1 from '@images/landing/Home__start__dl__item__dt__img--1.svg'
import start2 from '@images/landing/Home__start__dl__item__dt__img--2.svg'

import can0 from '@images/landing/Home__can__dl__item__img--0.svg'
import can1 from '@images/landing/Home__can__dl__item__img--1.svg'
import can2 from '@images/landing/Home__can__dl__item__img--2.svg'
import can3 from '@images/landing/Home__can__dl__item__img--3.svg'
import can4 from '@images/landing/Home__can__dl__item__img--4.svg'
import can5 from '@images/landing/Home__can__dl__item__img--5.svg'

import contactImg from '@images/landing/Home__contact__img.svg'
import rightContactImg from '@images/landing/Home__contact__right__img.svg'

const Home = (props) => {
  return (
    <div className='Home'>
      <div className='Home__hero'>
        <div className='container'>
          <div className='Home__hero__wrapper'>
            <h2 className='Home__hero__h2'>
              ใช้ LINE คุยเรื่องสำคัญ ทั้ง Chat หรือ <br />
              File จะไม่หายอีกต่อไป <br />
              <strong>
                ให้ <span>JotT</span> ช่วยคุณได้แล้ว วันนี้!
              </strong>
            </h2>
            <p className='Home__hero__p'>
              <strong>JotT คือ LINE Bot</strong> ที่ช่วยบันทึกบทสนทนา
              คัดลอกไฟล์ไปเก็บไว้ <br />
              บนคลาวด์เพื่อให้ข้อมูลของคุณไม่หายอีกต่อไป อีกทั้งสามารถ <br />
              ค้นหา ย้อนดูประวัติและบทสนทนาได้อย่างง่ายดาย
            </p>
            <div className='Buttons Buttons--jc-s'>
              <a className='ButtonLanding' href={props.new_user_session_path}>
                เริ่มให้ JotT ช่วยจดวันนี้
              </a>
            </div>
            <small className='Home__hero__small'>
              ใช้งานฟรี 30 วัน เริ่มต้นเพียง 500 บาทต่อเดือน ยกเลิกเมื่อไรก็ได้
            </small>
            <div className='Home__hero__image'>
              <img className='Home__hero__img' src={heroImg} />
            </div>
          </div>
        </div>
      </div>
      <div className='Home__can'>
        <div className='container'>
          <h3 className='Home__can__h3'>
            <strong>JotT</strong> จะช่วยคุณจดอะไรได้บ้าง...
          </h3>
          <dl className='Home__can__dl'>
            {[
              {
                label: 'ข้อความ',
                img: can0,
              },
              {
                label: 'รูปภาพ/อัลบั้ม',
                img: can1,
              },
              {
                label: 'เสียง/วีดิโอ',
                img: can2,
              },
              {
                label: 'แผนที่',
                img: can3,
              },
              {
                label: 'รายชื่อ/จำนวน',
                img: can4,
              },
              {
                label: 'ไฟล์งานต่างๆ',
                img: can5,
              },
            ].map((x, i) => (
              <div className='Home__can__dl__item' key={i}>
                <img className='Home__can__dl__item__img' src={x.img} />
                <div className='Home__can__dl__item__content'>
                  <dt className='Home__can__dl__item__dt'>{x.label}</dt>
                  <div className='Home__can__dl__item__hr'></div>
                </div>
              </div>
            ))}
          </dl>
        </div>
      </div>
      <div className='Anchor' id='why'></div>
      <div className='Home__why'>
        <div className='container'>
          <div className='Home__why__wrapper'>
            <h2 className='Home__why__h2'>ทำไมต้อง JotT ?</h2>
            <div className='Home__why__content'>
              <p className='Home__why__p'>
                หากคุณเป็นคนที่ใช้ LINE คุยงาน ไม่ว่าจะทั้งภายในบริษัท
                <br />
                หรือจะคุยกับลูกค้า คุณอาจจะพบเห็นปัญหาเหล่านี้ :
              </p>
              <ul className='Home__why__ul'>
                {[
                  'ย้ายเครื่องแล้ว Chat หาย !?',
                  'ไฟล์หมดอายุไปแล้ว ดันลืมโหลดไฟล์เก็บไว้',
                  'ค้นหาลำบาก ส่งต่องานให้อีกคนก็ยาก',
                ].map((x, i) => (
                  <li className='Home__why__ul__li' key={i}>
                    {x}
                  </li>
                ))}
              </ul>
              <p className='Home__why__p'>
                วันนี้! น้อง JotT จะช่วยคุณจดทุกอย่างและบันทึกไว้บนคลาวด์
                <br />
                ที่มีความปลอดภัยสูง เพื่อให้คุณสามารถทำงานได้อย่างราบรื่น
                <br />
                ไม่ต้องกลัวว่าจะหาย หรือหาอะไรไม่เจออีกต่อไป
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='Anchor' id='start'></div>
      <div className='Home__start'>
        <div className='container'>
          <h2 className='Home__start__h2'>เริ่มต้นง่ายๆ เพียง 3 ขั้นตอน</h2>
          <p className='Home__start__p'>
            ใช้เวลา 5 นาทีตอนนี้ จะได้ไม่เสียเวลา 5 ชม. ในการค้นหาในอนาคต
          </p>
          <div className='Home__start__image'>
            <img src={startImg} className='Home__start__img' />
          </div>

          <dl className='Home__start__dl'>
            {[
              {
                label: 'ลงชื่อเข้าใช้สู่ระบบผ่าน LINE',
                img: start0,
              },
              {
                label:
                  'พาน้อง JotT เข้ากลุ่มไลน์ที่ต้องการเชื่อมต่อและเก็บข้อความ',
                img: start1,
              },
              {
                label:
                  'หลังพาน้องเข้ากลุ่ม อย่าลืม Verify Code เพื่อเชื่อมต่อกับเรา',
                img: start2,
              },
            ].map((x, i) => (
              <div className='Home__start__dl__item' key={i}>
                <dt className='Home__start__dl__item__dt'>
                  <img className='Home__start__dl__item__dt__img' src={x.img} />
                  {i + 1}. {x.label}
                </dt>
              </div>
            ))}
          </dl>
          <div className='Home__start__blockquote__wrapper'>
            <blockquote className='Home__start__blockquote'>
              เพียงแค่นี้ <span>JotT</span> ก็จะช่วยคุณจดทุกอย่างแล้ว
            </blockquote>
          </div>
        </div>
      </div>
      <div className='Anchor' id='pricing'></div>
      <div className='Home__pricing'>
        <div className='container'>
          <h2 className='Home__pricing__h2'>
            มาทำให้ชีวิตการทำงาน ผ่าน LINE ดีขึ้นกันวันนี้
          </h2>
          <Plans partnerPackages={props.partnerPackages} />
          <div className='Buttons'>
            <a
              className='ButtonLanding ButtonLanding--orange'
              href={
                props.partner_code
                  ? `/packages/${props.partner_code}`
                  : props.new_user_session_path
              }
            >
              เริ่มเลยใช้ฟรี
              <span
                style={{
                  textDecoration: 'line-through',
                }}
              >
                7 วัน
              </span>
              <br />
              สูงสุด 30 วัน!!!
            </a>
          </div>
        </div>
      </div>
      <div className='Home__contact'>
        <div className='container'>
          <img src={contactImg} className='Home__contact__img' />
          <div className='Home__contact__right'>
            <h1 className='Home__contact__right__h1'>JotT</h1>
            <strong className='Home__contact__right__strong'>
              บริษัท เอ็มพลัส อินเตอร์เนชั่นแนล จำกัด
            </strong>
            <p className='Home__contact__right__p'>
              496-502 อาคารอัมรินทร์รินทร์พลาซ่า ชั้น 10 ถนนเพลินจิต <br />
              แขวงลุมพินี เขตปทุมวัน กรุงเทพมหานคร <br />
              10330 ประเทศไทย
            </p>
            <p className='Home__contact__right__p'>
              อีเมล: sales@mplus.co.th <br />
              พัฒนาโดย บริษัท เดต้า ว้าว จำกัด
            </p>
            <div className='Home__contact__right__image'>
              <img
                className='Home__contact__right__img'
                src={rightContactImg}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
