import React, {useState} from 'react'
import {get} from 'lodash'

import {axios} from '@lib/helpers'

import Tabs from '@components/molecules/Tabs'
import Modal from '@components/molecules/Modal'
import SourcesShowHeader from '@components/molecules/SourcesShowHeader'

const EncryptionIndex = (props) => {
  const {source} = props

  const [showDownload, setShowDownload] = useState(
    get(source, 'encryption', false)
  )
  const [alertModal, setAlertModal] = useState({isActive: false})

  const handleChangeSwitch = ({target: {checked}}) => {
    if (checked) {
      axios
        .put(
          `/sources/${get(source, 'source_type_name')}/${get(
            source,
            'source_id'
          )}/encryption`,
          {encryption: true}
        )
        .then(() => {
          setShowDownload(true)
        })
        .catch((err) => console.error(err))
    } else {
      setAlertModal({isActive: true})
    }
  }

  const handleDownload = async () => {
    const link = document.createElement('a')

    link.href = `/sources/${get(source, 'source_type_name')}/${get(
      source,
      'source_id'
    )}/encryption/download`
    link.target = '_blank'
    link.rel = 'noopener noreferrer'
    link.click()

    window.location.reload()
  }

  return (
    <React.Fragment>
      <div className='EncryptionIndex'>
        <SourcesShowHeader source={source} />
        <Tabs source={source} />

        <div className='EncryptionIndex__wrapper'>
          <div className='EncryptionIndex__switch-panel'>
            <div className='EncryptionIndex__switch-panel__svg' />

            <p className='EncryptionIndex__switch-panel__p'>
              การเข้ารหัสบทสนทนา
            </p>

            <p className='EncryptionIndex__switch-panel__p--light'>
              บทสนทนา ไฟล์ สื่อมัลติมีเดีย และการตั้งค่าต่างๆ ในห้องสนทนา
              จะมีเพียงผู้ที่มีคีย์เท่านั้นที่สามารถเข้าถึงได้
            </p>

            <div className='Switch'>
              <input
                type='checkbox'
                id='switch'
                onChange={handleChangeSwitch}
                checked={showDownload}
              />
              <label htmlFor='switch' />
            </div>
          </div>

          {showDownload && (
            <div className='EncryptionIndex__dashed'>
              <div className='EncryptionIndex__dashed__svg' />

              <p className='EncryptionIndex__dashed__p'>
                เมื่อดาวน์โหลดคีย์สำหรับการถอดรหัส{' '}
                <span className='EncryptionIndex__dashed__p--red'>
                  คีย์นี้จะไม่แสดงอีก
                  และระบบจะไม่สามารถสร้างคีย์ถอดรหัสเดิมอีกครั้งได้
                </span>
                <br />
                โปรดเก็บรักษาคีย์ถอดรหัสของคุณ ในกรณีที่ทำสูญหาย ติดต่อ&nbsp;
                <span className='EncryptionIndex__dashed__p--primary'>
                  sales@mplus.co.th
                </span>
                <br />{' '}
                <span className='EncryptionIndex__dashed__p--red'>
                  หากสูญหายจะไม่มีผู้ใดถอดรหัสได้รวมถึง JotT
                  กรุณาติดต่อเราเพื่อออกกุญแจและเริ่มเก็บข้อความใหม่
                </span>
              </p>

              <button
                className='Button'
                disabled={!get(source, 'has_main_key', false)}
                onClick={handleDownload}
              >
                ดาวน์โหลดคีย์ สำหรับการถอดรหัสห้องสนทนา
              </button>
            </div>
          )}

          <div className='EncryptionIndex__instruction'>
            <p className='EncryptionIndex__instruction__p'>
              ขั้นตอนการเปิดการเข้ารหัสบทสนทนา
            </p>
            <ol className='EncryptionIndex__instruction__ol'>
              <li className='EncryptionIndex__instruction__ol__li'>
                เปิดใช้งานการเข้ารหัสบทสนทนา
              </li>
              <li className='EncryptionIndex__instruction__ol__li'>
                ดาวน์โหลด “คีย์สำหรับการถอดรหัสห้องสนทนา”
              </li>
              <li className='EncryptionIndex__instruction__ol__li'>
                ทุกครั้งการเข้าใช้งาน
                กรุณาอัปโหลดคีย์ที่ดาวน์โหลดเพื่อเข้าอ่านข้อความ, ดูไฟล์
                หรือสื่อมัลติมีเดีย จะต้องใช้คีย์ ที่ดาวน์โหลดไปทุกครั้ง
                ดังนั้นโปรดเก็บรักษาคีย์ให้ดี และ
                <span className='EncryptionIndex__instruction__ol__li__span'>
                  ให้เฉพาะผู้ที่มีสิทธิ์เข้าถึงเท่านั้น
                </span>
              </li>
            </ol>
          </div>
        </div>

        <Modal modalData={alertModal} modalDataSet={setAlertModal}>
          <div className='EncryptionIndex__modal'>
            <div className='EncryptionIndex__modal__svg' />
            <h2 className='EncryptionIndex__modal__h2'>
              คำเตือน! สำหรับการปิดการเข้ารหัส
            </h2>
            <p className='EncryptionIndex__modal__p'>
              หากไม่มีคีย์ถอดรหัส จะไม่สามารถทำการปิดการใช้งานได้
              <br />
              หากต้องการจะปิดการเข้ารหัสสนทนา โปรดติดต่อ{' '}
              <span className='EncryptionIndex__modal__p__span'>
                sales@mplus.co.th
              </span>
            </p>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}

export default EncryptionIndex
