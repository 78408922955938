import React from 'react'
import {get} from 'lodash'
import className from 'classnames'

const TabsPackages = () => {
  return (
    <div className='Tabs'>
      {[
        {
          label: 'แพ็กเกจ',
          href: `/packages`,
        },
        {
          label: 'ใบเสร็จ',
          href: `/transactions`,
        },
      ].map((x, i) => (
        <a
          key={i}
          href={get(x, 'href')}
          className={className('Tabs__a', {
            'Tabs__a--active':
              get(x, 'href') === get(window, 'location.pathname'),
          })}
        >
          {get(x, 'label')}
        </a>
      ))}
    </div>
  )
}

TabsPackages.propTypes = {}

export default TabsPackages
