import React from 'react'
import classNames from 'classnames'
import {get, isNumber} from 'lodash'

import {formatBytes} from '@lib/helpers'
import correctImg from '@images/app/PackagesIndex__plans__dl__item__ul__li.svg'
import incorrectImg from '@images/app/PackagesIndex__plans__dl__item__ul__li--red.svg'

const Plans = ({
  hostUrl,
  smallPackageToken,
  mediumPackageToken,
  packageDetails,
  partnerPackages,
  partnerCode,
}) => {
  const customPack = partnerPackages?.map((item) => ({
    dt: item.name,
    p: {
      m: isNaN(item.recurring.month.recurring_amount)
        ? item.recurring.month.recurring_amount
        : Number(item.recurring.month.recurring_amount) || 'ฟรี',
      y: isNaN(item.recurring?.year?.recurring_amount)
        ? item.recurring?.year?.recurring_amount
        : Number(item.recurring?.year?.recurring_amount) || '',
    },
    rooms: item.rooms,
    messages: item.messages,
    storages: formatBytes(item.storages),
    encryption: item.encryption,
    retention_year: `${item.retention_year} ปี`,
    buttons: (
      <React.Fragment>
        <a
          className='Button Button--large Button--trans-blue'
          href={`${hostUrl}/payments?package=${item.token}&recurring=month&partner=${partnerCode}`}
          disabled={packageDetails?.name !== 'free'}
        >
          ซื้อเลย
        </a>
      </React.Fragment>
    ),
  })) ?? [
    {
      dt: 'Small',
      p: {
        m: 500,
        y: 5000,
      },
      rooms: 15,
      messages: 100000,
      storages: '10 GB',
      encryption: false,
      retention_year: '1 ปี',
      buttons: (
        <React.Fragment>
          <a
            className='Button Button--large Button--trans-blue'
            href={`${hostUrl}/payments?package=${smallPackageToken}&recurring=month`}
            disabled={packageDetails?.name !== 'free'}
          >
            ซื้อเลย
          </a>
        </React.Fragment>
      ),
    },
    {
      dt: 'Medium',
      p: {
        m: 5000,
        y: 50000,
      },
      rooms: 100,
      messages: 1500000,
      storages: '500 GB',
      encryption: false,
      retention_year: '3 ปี',
      buttons: (
        <React.Fragment>
          <a
            className='Button Button--large Button--trans-blue'
            href={`${hostUrl}/payments?package=${mediumPackageToken}&recurring=month`}
            disabled={packageDetails?.name !== 'free'}
          >
            ซื้อเลย
          </a>
        </React.Fragment>
      ),
    },
    {
      dt: 'Enterprise',
      p: {
        y: 'เริ่มต้น 25,000 บาท / เดือน',
      },
      rooms: 'ไม่จำกัด',
      messages: 'ไม่จำกัด',
      storages: '2 TB',
      encryption: true,
      retention_year: 'สูงสุดถึง 10 ปี',
      buttons: (
        <React.Fragment>
          <a
            className='Button Button--large Button--trans-blue'
            href='tel:+66854497373'
          >
            ติดต่อเรา โทร 08 5449 7373
          </a>
        </React.Fragment>
      ),
    },
  ]

  const template = [
    [
      {
        line1: undefined,
      },
      ...customPack.map((pack) => ({line1: get(pack, 'dt'), line2: 'Package'})),
    ],
    [
      {
        line1: 'จำนวนห้องสนทนา',
      },
      ...customPack.map((pack) => ({
        line1: get(pack, 'rooms')?.toLocaleString(),
        line2: isNumber(get(pack, 'rooms')) && 'ห้องสนทนา',
      })),
    ],
    [
      {
        line1: 'จำนวนข้อความ',
      },
      ...customPack.map((pack) => ({
        line1: get(pack, 'messages')?.toLocaleString(),
        line2: isNumber(get(pack, 'messages')) && 'ต่อเดือน',
      })),
    ],
    [
      {
        line1: 'พื้นที่จัดเก็บ',
      },
      ...customPack.map((pack) => ({
        line1: get(pack, 'storages'),
        unit1: get(pack, 'storages') === '2 TB' && '- ไม่จำกัด',
      })),
    ],
    [
      {
        line1: 'การเข้ารหัส',
      },
      ...customPack.map((pack) => ({
        line2: get(pack, 'encryption') ? 'มีการเข้ารหัส' : '-',
      })),
    ],
    [
      {
        line1: 'ระยะเวลาเก็บรักษาข้อมูล',
      },
      ...customPack.map((pack) => ({
        line2: get(pack, 'retention_year'),
      })),
    ],
    [
      {
        line1: 'ราคา',
      },
      ...customPack.map((pack) => ({
        line1: isNumber(get(pack, 'p.m'))
          ? get(pack, 'p.m').toLocaleString() + ' บาท'
          : get(pack, 'p.m') ?? 'Contact Us',
        small1: isNumber(get(pack, 'p.m')) && '/เดือน',
        line2: isNumber(get(pack, 'p.y'))
          ? get(pack, 'p.y').toLocaleString() + ' บาท / ปี'
          : get(pack, 'p.y'),
      })),
    ],
  ]

  return (
    <>
      {!hostUrl && (
        <div
          className='Home__pricing__table'
          style={{
            gridTemplateColumns: `repeat(${customPack.length + 1}, 1fr)`,
          }}
        >
          {template.map((temps, i) => (
            <div className='Home__pricing__table__row' key={i}>
              {temps.map((temp, j) => (
                <div key={j} className='Home__pricing__table__col'>
                  <div key={j} className='Home__pricing__table__col__detail'>
                    {(get(temp, 'line1') || isNumber(get(temp, 'line1'))) && (
                      <span
                        className={classNames(
                          'Home__pricing__table__col__detail__line',
                          {
                            Home__pricing__table__col__detail__title: j === 0,
                            Home__pricing__table__col__detail__hearder: i === 0,
                            Home__pricing__table__col__detail__main:
                              (i !== 0 || i !== get(temps, 'length') - 1) &&
                              j !== 0,
                          }
                        )}
                      >
                        {get(temp, 'line1')}
                        {get(temp, 'unit1') && (
                          <span>{get(temp, 'unit1')}</span>
                        )}
                        {get(temp, 'small1') && (
                          <small>{get(temp, 'small1')}</small>
                        )}
                      </span>
                    )}

                    {get(temp, 'line2') && (
                      <span
                        className={classNames(
                          'Home__pricing__table__col__detail__line',
                          {
                            Home__pricing__table__col__detail__subheadder:
                              i === 0,
                            Home__pricing__table__col__detail__second:
                              i !== 0 && i !== get(template, 'length') - 1,
                            'Home__pricing__table__col__detail__subheadder--small':
                              i === get(template, 'length') - 1,
                          }
                        )}
                      >
                        {get(temp, 'line2')}
                        {get(temp, 'unit2') && (
                          <span>{get(temp, 'unit2')}</span>
                        )}
                        {get(temp, 'small2') && (
                          <small>{get(temp, 'small2')}</small>
                        )}
                      </span>
                    )}
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      )}

      <div className='PackagesIndex__plans__dl'>
        {customPack.map((pack, i) => (
          <div key={i} className='PackagesIndex__plans__dl__item'>
            <div className='PackagesIndex__plans__dl__item__header'>
              <div className='PackagesIndex__plans__dl__item__header__package'>
                {get(pack, 'dt')} Package
              </div>
              {isNumber(get(pack, 'p.m')) ? (
                <div className='PackagesIndex__plans__dl__item__header__price'>
                  <span>{get(pack, 'p.m').toLocaleString()}</span> บาท / เดือน
                </div>
              ) : (
                <div className='PackagesIndex__plans__dl__item__header__price'>
                  <span>{get(pack, 'p.m') ?? 'ติดต่อเรา'}</span>
                </div>
              )}
              {isNumber(get(pack, 'p.y')) ? (
                <small>
                  หรือ {get(pack, 'p.y').toLocaleString()} บาทต่อปี
                  (รวมภาษีมูลค่าเพิ่ม 7%)
                </small>
              ) : (
                <small>
                  <span>{get(pack, 'p.y') ?? 'ติดต่อเรา'}</span>
                </small>
              )}
            </div>

            <div className='PackagesIndex__plans__dl__item__details'>
              <div className='PackagesIndex__plans__dl__item__details__row'>
                <img src={correctImg} />
                <span>
                  {get(pack, 'messages') === 'ไม่จำกัด' ? (
                    'ไม่จำกัด! จำนวนห้องสนทนา'
                  ) : (
                    <>
                      รองรับ{' '}
                      <b>{get(pack, 'rooms')?.toLocaleString()} ห้องสนทนา</b>
                    </>
                  )}
                </span>
              </div>
              <div className='PackagesIndex__plans__dl__item__details__row'>
                <img src={correctImg} />
                <span>
                  {get(pack, 'rooms') === 'ไม่จำกัด' ? (
                    'ไม่จำกัด! ข้อความ'
                  ) : (
                    <>
                      รองรับ{' '}
                      <b>{get(pack, 'messages')?.toLocaleString()} ห้องสนทนา</b>
                    </>
                  )}
                </span>
              </div>
              <div className='PackagesIndex__plans__dl__item__details__row'>
                <img src={correctImg} />
                <span>
                  พื้นที่จัดเก็บบนระบบ Cloud{' '}
                  <b>
                    {!get(pack, 'p.m')
                      ? `${get(pack, 'storages')} หรือแบบไม่จำกัด`
                      : `สูงสุด ${get(pack, 'storages')} รองรับ`}
                  </b>
                </span>
              </div>

              <div className='PackagesIndex__plans__dl__item__details__row'>
                <img src={correctImg} />
                <span>
                  ระยะเวลาเก็บรักษาข้อมูล <b>{get(pack, 'retention_year')}</b>
                </span>
              </div>
              <div className='PackagesIndex__plans__dl__item__details__row'>
                <img src={pack?.encryption ? correctImg : incorrectImg} />
                <span>
                  {!get(pack, 'encryption') && 'ไม่'}รองรับการเข้ารหัส
                </span>
              </div>
              {hostUrl && (
                <div className='PackagesIndex__plans__dl__item__details__buttons'>
                  {get(pack, 'buttons')}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

Plans.propTypes = {}

export default Plans
