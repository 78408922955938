import React from 'react'
import PropTypes from 'prop-types'
import {get} from 'lodash'
import className from 'classnames'
import * as Cookies from 'js-cookie'

import {t, axios} from '@lib/helpers'

const KEY = '_chat_storage_confirmation_snooze'

const Alert = ({profile_path}) => {
  if (Cookies.get(KEY)) {
    return null
  }

  return (
    <div className='Alert__wrapper'>
      <p className='Alert__p'>
        เพื่อสะดวกต่อการติดต่อ กรุณากรอกอีเมลสำหรับติดต่อและรับใบเสร็จ
      </p>

      <a className='Alert__a' href={profile_path}>
        ไปยังหน้ากรอกอีเมล
      </a>

      <a
        className='Alert__a'
        onClick={() => {
          Cookies.set(KEY, 1, {
            expires: 1, // 1 day.
          })

          window.location.reload()
        }}
      >
        ไม่ใช่ตอนนี้
      </a>
    </div>
  )
}

Alert.propTypes = {}

export default Alert
