import React from 'react'
import {get, map, isEmpty, isUndefined} from 'lodash'

import {axios} from '@lib/helpers'

import Modal from '@components/molecules/Modal'

const ModalEditRoomName = ({modalData, modalDataSet}) => {
  const [errors, errorsSet] = React.useState()
  const [isLoading, isLoadingSet] = React.useState(false)

  return (
    <Modal modalData={modalData} modalDataSet={modalDataSet}>
      <form
        className='Modal__form'
        onSubmit={(e) => {
          e.preventDefault()

          const $els = get(e, 'target.elements')
          const formData = new FormData()

          const inputs = map($els, (x) => {
            return {
              name: get(x, 'name'),
              value: get(x, 'name') == 'logo' ? uploadFile : get(x, 'value'),
            }
          }).filter(
            (x) => !isEmpty(get(x, 'name')) && !isUndefined(get(x, 'value'))
          )

          inputs.forEach((x) => {
            formData.append(get(x, 'name'), get(x, 'value'))
          })

          isLoadingSet(true)

          axios
            .patch(
              `/sources/${get(modalData, 'data.source_type_name')}/${get(
                modalData,
                'data.source_id'
              )}`,
              formData
            )
            .then((res) => {
              window.location.reload()
            })
            .catch((e) => {
              isLoadingSet(false)
              errorsSet(get(e, 'response.data.errors'))
            })
        }}
      >
        <h2 className='Modal__h2'>ตั้งชื่อห้องสนทนา</h2>

        <p className='Modal__p'>ตัวอักษรหรือตัวเลขเท่านั้น</p>

        <label className='Form__label'>
          <input
            type='text'
            className='Input'
            placeholder='ห้องสนทนา'
            onChange={(e) =>
              modalDataSet({
                ...modalData,
                data: {...modalData.data, name: e.target.value},
              })
            }
            value={get(modalData, 'data.name') || ''}
            name='name'
            maxLength='20'
          />
        </label>

        <button className='Button Button--large'>ยืนยัน</button>
        <a
          className='Button Button--large Button--trans'
          onClick={() => {
            modalDataSet({
              ...modalData,
              isActive: false,
            })
          }}
        >
          ยกเลิก
        </a>
      </form>
    </Modal>
  )
}

ModalEditRoomName.propTypes = {}

export default ModalEditRoomName
