import React, {useState} from 'react'
import {get, map, isEmpty, isUndefined} from 'lodash'

import {axios} from '@lib/helpers'

import Modal from '@components/molecules/Modal'

const ModalEditRemark = ({modalData, modalDataSet, source = 'files'}) => {
  const [isLoading, isLoadingSet] = useState(false)
  const [errorMessage, errorMessageSet] = useState('')

  return (
    <Modal modalData={modalData} modalDataSet={modalDataSet}>
      <form
        className='Modal__form'
        onSubmit={(e) => {
          e.preventDefault()

          const $els = get(e, 'target.elements')

          const formData = new FormData()

          const inputs = map($els, (x) => {
            return {
              name: get(x, 'name'),
              value: get(x, 'value'),
            }
          }).filter(
            (x) => !isEmpty(get(x, 'name')) && !isUndefined(get(x, 'value'))
          )

          inputs.forEach((x) => {
            formData.append(get(x, 'name'), get(x, 'value'))
          })

          isLoadingSet(true)

          axios
            .patch(
              get(modalData, 'data.data.url').replace('files', source),
              formData
            )
            .then((res) => {
              window.location.reload()
            })
            .catch((e) => {
              isLoadingSet(false)
              errorMessageSet(
                get(e, 'response.data.errors') || get(e, 'message')
              )
            })
        }}
      >
        <h2 className='Modal__h2'>แก้ไขโน๊ต</h2>

        <label className='Form__label'>
          <textarea
            type='text'
            className='Input'
            placeholder='โน๊ต...'
            defaultValue={get(modalData, 'data.remark')}
            name='remark'
            multiple
          />
        </label>

        <div className='Modal__error'>{errorMessage}</div>

        <button className='Button Button--large' disabled={isLoading}>
          {isLoading ? 'กำลังบันทึก ...' : 'บันทึก'}
        </button>
        <a
          className='Button Button--large Button--trans'
          disabled={isLoading}
          onClick={() => {
            modalDataSet({
              ...modalData,
              isActive: false,
            })
            errorMessageSet('')
          }}
        >
          ยกเลิก
        </a>
      </form>
    </Modal>
  )
}

ModalEditRemark.propTypes = {}

export default ModalEditRemark
